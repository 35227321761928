.partner-popup-modal {
    background-color: #fff;
    width: 90%;
    height: 90%;
    z-index: 4;
    margin: 0 auto;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    @media only screen and (max-width: 500px) {
        width: 95%;
        height: 95%;
    }



    &__content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 20px;

        &--top {
            
        }

        &--main {
            margin-top: 10px;
        }


    }
}

.partner-popup-top {
    &__title {
        text-align: center;
        &--text {
            text-align: center;
            font-size: 1.8vw;
            font-weight: 700;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
            padding: 40px 20px;

            @media only screen and (max-width: 900px) {
                font-size: 18px;
            }
        }

    }

    &__close-btn {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
}

.partner-popup-country {
    &__section {
        margin-top: 2.5vh;

        &--title {
            text-align: center;
            font-size: 1.4vw;
            font-weight: 500;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
            padding: 16px;
            @media only screen and (max-width: 900px) {
                font-size: 13px;
            }
        }

        &--logos {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}

.partner-view {
    flex: 0 0 calc(25% - 20px);
    padding: 20px;
}