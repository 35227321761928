.description {
    margin: 5vh 5vw 0vh;
    padding: 10px 0px 60px;

    .input-group {
        display: flex;
        margin: 45px auto;
        width: 60%;
        font-size: 80%;
        align-items: flex-end;

        .input {
            background-color: transparent;
            border: none;
            padding: 10px;
            outline: none;
            flex: 1;
            text-align: center;

            &::placeholder {
                font-weight: 100;
                color: #fff;
            }


            input {

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            input[type=number] {
                -moz-appearance: textfield;
            }



        }

        .count-text {
            font-family: 'Montserrat', sans-serif;
            font-size: 12px;
            color: #777781;
            padding: 10px;
            font-weight: 300;
        }






    }


    @media only screen and (max-width: 1200px) {
        .input-group {
            width: 80%;
        }

    }

    @media only screen and (max-width: 749px) {
        padding: 0px 0px 60px;

        .input-group {
            display: block;
            margin: 45px auto;
            width: 100%;
            max-width: 500px;
            text-align: right;
        }

    }

}