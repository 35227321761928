.edit-listing-page{
    background-color: #000000;
    background-image: linear-gradient(215deg, #000000 0%, #052034 100%);
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    width: auto;
    height: 100%;
}

.image-box {
    width: 80%;
    margin: auto;
    aspect-ratio: 3/1;
    display: flex;
}

@media only screen and (max-width: 960px) {
    .image-box {
        width: 100%
    }
}

.registration-button {
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    font-size: 80%;
    border: solid 1px transparent;
    padding: 5px;
    font-weight: 400;
    border-radius: 25px;
    margin-left: 5px;
    height: fit-content;
    width: fit-content;
}

.registration-button:hover:not([disabled]),
.registration-button:focus:not([disabled]) {
    color: #ff9804;
    outline: none;
}

.registration-button:disabled {
    border: 1px solid #555;
    color: #555;
}


.navigation-bar {
    margin: 5vh 5vw;

    .progress-bar {}

    .nav-button-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 20px 0px;
        flex-wrap: wrap;

        .nav-button {
            flex: 1;
            min-width: 150px;
            text-align: center;
            padding: 0px 5px;

            .button {
                text-align: center;
                display: flex;
                margin: auto;
                justify-content: center;
                align-items: center;
                padding: 8px 0px;

                font-family: 'Montserrat', sans-serif;
                color: #fff;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 90%;
                background-color: transparent;
                outline: none;
                border: none;
                border-bottom: 2px solid transparent;
                transition: border-bottom 0.2s;

                &:hover,
                &:active {
                    border-bottom: 2px solid #ffa626;
                }
            }

            .active {
                border-bottom: 2px solid #ffa626;
            }
        }

    }

}

.navigation-bar-small {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5vh 5vw;

    .left-arrow {}

    .right-arrow {}

    .title {
        flex: 1;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        

        .text {
            font-family: 'Montserrat', sans-serif;
            color: #fff;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 90%;
            background-color: transparent;
            outline: none;
            border: none;
            border-bottom: 2px solid #ffa626;
            transition: border-bottom 0.2s;
        }


    }

}