.header {
    padding: 2vh 3vw;

    &__is-fixed {
        width: 100%;
        top: 0px;
        position: fixed;

    }

    &__is-dark {
        background-color: #000000;
        background-image: linear-gradient(215deg, #000000 0%, #052034 100%);
        background-size: cover;
        background-position: center;
        z-index: 9;
    }

    .main-row {
        display: flex;
        align-items: center;

        justify-content: space-between;

        .logo {
            &__image {
                width: auto;
                max-height: 40px;
                height: 6vw;
            }
        }

        &__nav {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;

            .link {
                display: inline-block;
                padding: 0 10px;

                &__text {
                    padding: 8px 0;
                    font-family: 'Montserrat', sans-serif;
                    color: #fff;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 16px;
                    border-bottom: 2px solid transparent;
                    transition: border-bottom 0.2s;

                    &:hover, &:active{
                        border-bottom: 2px solid #ffa626;
                    }

                    &.active{
                        border-bottom: 2px solid #ffa626;
                    }
                }
            }


        }

        .side-btns {
            display: flex;

            .dropdown {
                padding: 0 10px;
                position: relative;

                &:hover {
                    border: none;
                    color: #ffa626;

                    .dropdown__content {
                        display: block;
                    }

                    .dropdown__btn {
                        border: 2px solid #ffa626;
                        color: #ffa626;
                    }

                    .dropdown__icon-btn {
                        border: none;
                        color: #ffa626;
                    }
                }


                &__btn {
                    padding: 5px 10px;
                    color: #fff;
                    background-color: transparent;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-family: 'Montserrat', sans-serif;
                    border: 1px solid #777781;
                    transition: border 0.2s color 0.2s;
                    width: 90px;
                }

                &__icon-btn {
                    padding: 5px 10px;
                    color: #fff;
                    background-color: transparent;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-family: 'Montserrat', sans-serif;
                    border: 2px solid #fff;
                    transition: border 0.2s color 0.2s;
                    width: 90px;
                    padding: 5px 5px;
                    border: none;
                    height: 40px;
                }

                &__content {

                    display: none;
                    position: absolute;
                    width: 90px;
                    border: 1px solid #777781;
                    z-index: 10;

                    &--link {
                        color: #fff;
                        padding: 12px 12px;
                        text-decoration: none;
                        display: block;
                        text-transform: uppercase;
                        font-size: 10px;
                        text-align: center;
                        background-color: #020d15;
                        font-family: 'Montserrat', sans-serif;

                        &:hover {
                            background-color: #ffa626;
                            color: #fff;
                            text-decoration: none;
                        }
                    }

                    &--details {
                        background-color: #020d15;
                        padding: 5px 12px;

                        &__text {
                            display: block;
                            color: #777781;
                            font-size: 8px;
                            text-align: center;
                            padding-bottom: 5px;
                            text-transform: uppercase;
                            border-bottom: #777781 solid 0.2px;
                            font-family: 'Montserrat', sans-serif;
                            word-break: break-word;
                        }
                    }

                }


            }
        }

    }

}

.drawer {
    min-width: 200px;
    width: 30%;
    height: 100%;
    min-height: 100vh;
    background-color: #020d15;
    padding: 20px 0px;

    .section {
        padding: 10px 0px;
        border-bottom: #052034 solid 0.2px;
        background-color: #020d15;

        &__title {
            display: block;
            color: #777781;
            font-size: 12px;
            text-align: center;
            padding: 10px;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
            word-break: break-word;
        }

        &__btn {
            color: #fff;
            padding: 12px 12px;
            text-decoration: none;
            display: block;
            text-transform: uppercase;
            font-size: 10px;
            text-align: center;
            background-color: #020d15;
            font-family: 'Montserrat', sans-serif;

            &:hover {
                background-color: #ffa626;
                color: #fff;
                text-decoration: none;
                cursor: pointer;
            }

            &:active{
                color: #ffa626;
                
            }
            &.active{
                color: #ffa626;
                border-right: #ffa626 3px solid;
                background-color: #052033;
                &:hover{
                    background-color: #ffa626;
                    color: #fff;
                }
            }

            
        }
    }
}