.music-images {
    margin: 5vh 5vw 0vh;
    padding: 10px 0px 60px;

    .collage-group {
        display: flex;
        margin: 15px auto;
        width: 60%;
        font-size: 80%;
        align-items: center;
        flex-wrap: wrap;
    }

    .input-group {
        display: flex;
        margin: 0px auto;
        width: 60%;
        font-size: 80%;
        align-items: flex-end;

        .input {
            background-color: transparent;
            border: none;
            padding: 10px;
            outline: none;
            flex: 1;
            text-align: center;

            &::placeholder {
                font-weight: 100;
                color: #fff;
            }


            input {

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            input[type=number] {
                -moz-appearance: textfield;
            }

        }

        .input-text {
            flex: 1;
            align-items: baseline;
            display: flex;
            padding: 10px;
            text-align: center;
            justify-content: center;

            .icon {
                color: #777781;
                font-size: 17px;
                margin-right: 5px;
            }

            .text {
                font-size: 15px;
                font-family: 'Montserrat', sans-serif;
                font-weight: 300;
                color: #777781;
                margin-left: 5px;
                text-align: justify;
            }
        }

        .otp-verification {
            flex: 1;
            display: flex;
            padding: 10px;
        }

        .verify-btn {
            flex: 1;
            padding: 10px;
        }


    }

    .link {
        margin: 0px auto;
        width: 40%;
    }

    .image-section {
        margin: 40px 0px;

        .compressor-link{
            cursor: pointer;
            font-weight: 500;
            text-transform: uppercase;

            &:hover{
                cursor: pointer;
                color: #ffa626;   
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .input-group {
            width: 80%;
        }

        .collage-group {
            width: 80%
        }

        .link {
            width: 60%;
        }

    }

    @media only screen and (max-width: 749px) {
        padding: 0px 0px 60px;

        .collage-group {
            width: 100%
        }

        .input-group {
            display: block;
            margin: 25px auto;
            width: 100%;
            max-width: 500px;


            .verify-btn {
                text-align: center;
                padding: 30px 10px;
            }

            .input-text {
                justify-content: center;
                padding: 30px 10px;
            }
        }

        .link {
            margin: 0px auto;
        }

    }

    .collage-select-box {
        flex: 1;
        display: flex;
        text-align: center;
        justify-content: center;
        width: 100%;
        min-width: 110px;
    }

    .agree-terms-btn {
        width: 80%;
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 60px auto;
    }

    .main-btn-view {
        text-align: center;
        margin: 0px 0px 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        .error-icon {
            margin-left: 20px;
        }
    }

    .preview-btn{
        text-align: center;
        margin: 0px 0px 30px;
        font-size: 13px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        color: #777781;

        &:hover{
            cursor: pointer;
            color: #ffa626;   
        }
    }

}