.listing-page {
    &__first-block {
        background-color: #fff;
        padding: 6vh 8vw;


        .top-view {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 20px;

            &__left {
                &--name {
                    color: #000;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 3vw;
                    font-weight: 500;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                }

                @media only screen and (max-width: 700px) {
                    &--name {
                        font-size: 22px;
                    }
                }


                &--location {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 5px;

                    &__icon {
                        font-size: 16px;
                        color: #777781;
                        margin-right: 10px;
                    }

                    &__text {
                        color: #777781;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 15px;
                        font-weight: 300;

                    }

                }

                &--insured {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 5px;

                    &__icon {
                        font-size: 16px;
                        color: #777781;
                        margin-right: 10px;
                    }

                    &__text {
                        color: #777781;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 15px;
                        font-weight: 300;

                    }

                }


                &--languages {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    &__icon {
                        font-size: 16px;
                        color: #777781;
                        margin-right: 10px;
                    }

                    &__text {
                        color: #777781;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 15px;
                        font-weight: 300;
                    }

                }
            }

            &__right {
                &--share-btn {
                    color: #000;

                    &__icon {
                        font-size: 13px;
                        margin-right: 5px;
                    }

                    &__text {
                        font-family: 'Montserrat', sans-serif;
                        font-size: 15px;
                        font-weight: 400;
                        text-transform: capitalize;
                    }

                    &:hover {
                        cursor: pointer;
                        color: #ffa626;
                    }
                }
            }
        }

        .collage-images {
            margin-bottom: 30px;

            .image-box {
                width: 100%
            }
        }

        .detail-view {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;


            &__left {
                flex: 2;

                .value-swiper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 20px;

                    .mySwiper {
                        z-index: 0;
                    }

                    &__single {
                        flex: 1;
                        min-width: 80px;
                        text-align: center;
                        padding: 2px;

                        &--icon {
                            color: #000;
                            font-size: 25px;
                            margin-bottom: 3px;
                        }

                        &--title {
                            font-family: 'Montserrat', sans-serif;
                            font-size: 13px;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }

                        &--value {
                            font-family: 'Montserrat', sans-serif;
                            font-size: 13px;
                            font-weight: 300;
                            color: #777781;
                        }
                    }
                }

                & .swiper-container {
                    flex: 1;
                }

                & .swiper-slide {
                    height: auto;
                    padding: 20px 1vw;
                    /* Center slide text vertically */
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                }

                .values {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    border-bottom: solid 1px #f4f4f4;
                    padding-bottom: 20px;

                    &__single {
                        flex: 1;
                        min-width: 80px;
                        text-align: center;
                        padding: 2px;

                        &--icon {
                            color: #000;
                            font-size: 25px;
                            margin-bottom: 3px;
                        }

                        &--title {
                            font-family: 'Montserrat', sans-serif;
                            font-size: 13px;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }

                        &--value {
                            font-family: 'Montserrat', sans-serif;
                            font-size: 13px;
                            font-weight: 300;
                            color: #777781;
                        }
                    }
                }


                .description-view {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 15px;
                    font-weight: 300;
                    color: #000;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-bottom: solid 1px #f4f4f4;

                    &__title {
                        font-size: 22px;
                        font-weight: 400;
                        text-align: center;
                        margin-bottom: 10px;
                    }
                }

                .set-list_past-clients {
                    display: flex;
                    flex-direction: row;

                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-bottom: solid 1px #f4f4f4;

                    .set-list {
                        flex: 1;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 15px;
                        font-weight: 300;
                        color: #000;

                        &__title {
                            font-size: 22px;
                            font-weight: 400;
                            text-align: center;
                            margin-bottom: 10px;
                        }
                    }

                    .with-past-clients {
                        padding: 10px;
                        border-right: solid 1px #f4f4f4;
                    }

                    .past-clients {
                        flex: 1;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 15px;
                        font-weight: 300;
                        color: #000;

                        &__title {
                            font-size: 22px;
                            font-weight: 400;
                            text-align: center;
                            margin-bottom: 10px;
                        }
                    }

                    .with-set-list {
                        padding: 10px;
                        margin-left: -1px;
                        border-left: solid 1px #f4f4f4;
                    }

                    @media only screen and (max-width: 500px) {
                        display: block;

                        .with-set-list {
                            border-top: solid 1px #f4f4f4;
                            padding: 20px 0px 0px;
                            margin-left: 0px;
                            border-left: none;
                        }

                        .with-past-clients {
                            padding: 0px;
                            border-right: none;
                            padding: 0px 0px 20px;
                        }
                    }

                }
            }

            &__right {
                flex: 1;
                padding-left: 40px;
                padding-right: 0px;
            }

            @media only screen and (max-width: 960px) {
                display: block;

                &__right {
                    padding-top: 30px;
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }
    }

    &__music-block {
        background-color: #000000;
        padding: 8vh 8vw;

        &--title {
            font-family: 'Montserrat', sans-serif;
            font-size: 25px;
            font-weight: 400;
            color: #fff;
            margin-bottom: 30px;
            text-align: center;
        }

        & .music-swiper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            .mySwiper {
                z-index: 0;
            }

            &__iframe {
                width: 100%;
                aspect-ratio: 16 / 9;
                padding: 5px 5px;
                border-radius: 10px;
            }
        }

        & .swiper-container {
            flex: 1;
        }

        & .swiper-slide {
            height: auto;
            padding: 1vh 0.5vw;
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
        }

        & .music-view {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            &__iframe {
                min-width: 350px;
                width: 33.3%;
                aspect-ratio: 16 / 9;
                margin-bottom: 20px;
                padding: 10px;
                border-radius: 10px;
            }
        }
    }

    &__bottom-block {
        background-color: #fff;
        padding: 8vh 8vw;

        & .tr-calendar-view {
            display: flex;
            flex-direction: row;

            & .technical-rider {
                flex: 1;
                font-family: 'Montserrat', sans-serif;
                font-size: 15px;
                font-weight: 300;
                color: #000;
                padding: 0px;
                padding-top: 0;
                padding-bottom: 0;
                // border-right: solid 1px #f4f4f4;

                &__title {
                    font-size: 22px;
                    font-weight: 400;
                    text-align: center;
                    margin-bottom: 15px;
                }
            }

            & .calendar {
                flex: 1;
                font-family: 'Montserrat', sans-serif;
                font-size: 15px;
                font-weight: 400;
                color: #000;
                padding: 20px;
                padding-top: 0;
                padding-bottom: 0;
                margin-left: -1px;
                border-left: solid 1px #f4f4f4;

                &__title {
                    font-size: 22px;
                    font-weight: 400;
                    text-align: center;
                    margin-bottom: 10px;
                }

                &__view {}
            }
        }

        & .ratings-section {
            margin-top: 8vh;
            padding: 4vh 0;
            border-top: solid 1px #f4f4f4;
            font-family: 'Montserrat', sans-serif;
            font-size: 15px;
            font-weight: 400;
            color: #000;

            &__title {
                display: flex;
                flex-direction: row;
                align-items: center;

                &--star-icon {
                    font-size: 22px;
                    font-weight: 400;
                    margin-right: 5px;
                }

                &--rating {
                    font-size: 22px;
                    font-weight: 400;
                    margin-right: 5px;
                }

                &--dot-icon {
                    font-size: 5px;
                    font-weight: 400;
                    margin-right: 5px;
                }
            }

            &__details {
                padding: 20px 0;

                &--row {
                    display: flex;
                    flex-direction: row;

                    @media only screen and (max-width: 700px) {
                        display: block;
                    }

                    .detail {
                        padding: 5px 0;
                        flex: 1;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0 2vw;

                        &__variable {
                            font-family: 'Montserrat', sans-serif;
                            font-size: 15px;
                            font-weight: 400;
                            color: #000;
                        }

                        &__value {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            &--progress {
                                width: 150px;
                                margin-right: 10px;

                                @media only screen and (max-width: 900px) {
                                    width: 100px;
                                }

                                @media only screen and (max-width: 350px) {
                                    width: 50px;
                                }

                                .MuiLinearProgress-colorPrimary {
                                    background-color: #ffd79d;
                                }

                                .MuiLinearProgress-barColorPrimary {
                                    background-color: #ff9804;
                                }


                            }

                            &--label {
                                font-size: 12px;
                                font-weight: 300;
                                color: #000;
                            }
                        }
                    }

                }


            }

            &__ratings {

                justify-content: center;
                display: grid;
                grid-template-columns: repeat(auto-fill, calc(max(50%, 250px)));

                @media only screen and (max-width: 600px) {
                    grid-template-columns: repeat(auto-fill, 90vw);
                }

                .rating-single {
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-bottom: 10px;
                }

            }

            .more-btn{
                text-align: center;
            }
        }


    }


}