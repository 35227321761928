.home-page {
    &__dark-background {
        background-color: #000000;
        background-image: linear-gradient(215deg, #000000 0%, #052034 100%);
        background-size: cover;
        background-position: center;
        height: 100%;
    }

    &__banner {
        margin: 0px;
        padding-top: 11rem;
        padding-bottom: 18rem;
        text-align: center;
        color: #fff;

        &--view {
            width: 80%;
            margin-bottom: 5rem;
            margin-left: auto;
            margin-right: auto;

            // &__text {
            //     font-family: 'Montserrat', sans-serif;
            //     font-size: 3.8vw;
            //     font-weight: 300;
            //     text-align: center;
            //     @media only screen and (max-width: 900px) {
            //         font-size: 30px;
            //     }

            // }

            &__text {
                font-family: 'Montserrat', sans-serif;
                font-size: 6vw;
                text-align: center;
                font-weight: 300;

                @media only screen and (max-width: 900px) {
                    font-size: 30px;
                }
            }

            @media only screen and (max-width: 900px) {
                width: 70%;
            }

            @media only screen and (max-width: 500px) {
                width: 90%;
            }
        }

    }
}

/* ------------Animation for header title------------ */
.banner-words span::before {
    content: "party";
    color: #03a993;
    animation: changeWord 10s infinite 0s;
}

@keyframes changeWord {
    0% {
        color: #03a993;
        content: "party";
        opacity: 1;
    }

    10% {
        opacity: 0;

    }

    20% {
        color: #ff1a54;
        content: "wedding";
        opacity: 1;
    }

    30% {
        opacity: 0;

    }

    40% {
        color: #ffa626;
        content: "birthday";
        opacity: 1;
    }

    50% {
        opacity: 0;

    }

    60% {
        color: #03a993;
        content: "celebration";
        opacity: 1;
    }

    70% {
        opacity: 0;

    }

    80% {
        color: #ff1a54;
        content: "event";
        opacity: 1;
    }

    90% {
        opacity: 0;

    }

    100% {
        color: #ffa626;
        content: "birthday";
        opacity: 1;
    }
}

.main-block {



    &__area {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 5vw;
        padding-right: 5vw;
        --tw-bg-opacity: 1;
        background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

        & .main-value-swiper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        & .swiper-container {
            flex: 1;
        }

        & .swiper-slide {
            height: auto;
            padding: 20px;
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
        }

        &--card {
            flex: 1;
            padding: 25px;
        }

        & .expand-section {
            width: 100%;
            --tw-bg-opacity: 1;
            background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
            border-radius: 0.5rem;
            margin-top: 20px;

            &__image-view {
                --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
                box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
                padding-left: 3vw;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 1.25rem;
                border-radius: 2px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                background-color: transparent;
                aspect-ratio: 3 / 1;

                &--title {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 3.5vw;
                    font-weight: 400;
                    color: #fff;
                    width: fit-content;

                    @media only screen and (max-width: 500px) {
                        font-size: 22px;
                    }
                }
            }

            &__detail {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 20px;

                &--description {
                    margin-right: 0.5rem;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1.4vw;
                    font-weight: 200;

                    @media only screen and (max-width: 1100px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    & .values-swiper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .swiper-container {
        flex: 1;
    }

    & .swiper-slide {
        height: auto;
        padding: 20px;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
}

.listing-block {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 5vw;
    padding-right: 5vw;
    --tw-bg-opacity: 1;
    background-color: #000000;

    &__header {
        margin-bottom: 30px;

        &--title {
            font-family: 'Montserrat', sans-serif;
            font-size: 2.1vw;
            font-weight: 500;
            color: #fff;
            text-transform: capitalize;
            margin-bottom: 10px;

            @media only screen and (max-width: 850px) {
                font-size: 20px;
            }
        }

        &--description {
            font-family: 'Montserrat', sans-serif;
            font-size: 1.2vw;
            font-weight: 300;
            color: #fff;
            text-transform: capitalize;

            @media only screen and (max-width: 850px) {
                font-size: 10px;
            }
        }
    }

    & .listing-swiper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .swiper-container {
        flex: 1;
    }

    & .swiper-slide {
        height: auto;
        padding: 20px;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }


}

.partner-block {
    display: flex;
    flex-direction: column;
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 5vw;
    padding-right: 5vw;
    background-color: #fff;

    &__header {
        margin-bottom: 20px;

        &--title {
            font-family: 'Montserrat', sans-serif;
            font-size: 2.1vw;
            font-weight: 500;
            color: #000;
            text-transform: capitalize;
            margin-bottom: 10px;

            @media only screen and (max-width: 850px) {
                font-size: 20px;
            }
        }
    }

    & .partner-swiper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .swiper-container {
        flex: 1;
    }

    & .swiper-slide {
        height: auto;
        padding: 20px;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
}

.footer-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f4f4f4;

    &__link {
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.1vw;
        font-weight: 300;
        padding: 10px;
        transition: color 500ms;

        @media only screen and (max-width: 800px) {
            font-size: 10px;
        }

        &:hover {
            color: #ffa626;
        }
    }
}


/* ----------Animation Search bar---------- */


.animation-hidden-bar {
    position: relative;
    animation-name: hiddenSearchBar;
    animation-duration: 0.4s;
}

@keyframes hiddenSearchBar {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0.1);
    }
}

.animation-show-bar {
    position: relative;
    animation-name: showSearchBar;
    animation-duration: 0.4s;
}

@keyframes showSearchBar {
    0% {
        opacity: 0;
        transform: scale(0.1);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}