.template-page{
    padding: 6vh 6vw;
    background-color: #fff;

    &__main-section{
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(auto-fill,calc(max(25%, 250px)));

        &--template {
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 20px;
        }
    }
}