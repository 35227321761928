.sign-up-background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 1);
    background-image: linear-gradient(215deg, rgba(0, 0, 0, 0), #052034 100%);
    z-index: 10;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    .main-box {
        background-color: #000000;
        background-image: linear-gradient(215deg, #000000 0%, #052034 100%);
        border: 0.2px solid #777781;
        box-shadow: 0 0 10px #777781;
        padding: 0px 3vw;
        min-width: 250px;
        max-width: 900px;
        overflow-y: auto;
        max-height: 90%;
        width: 60%;
        display: flex;
        flex-direction: column;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }


        @media only screen and (max-width: 1200px) {
            width: 80%;
        }

        @media only screen and (max-width: 1000px) {
            width: 90%;
        }

        @media only screen and (max-width: 600px) {
            width: 90%;
        }

        &__btn-row {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
            margin: 30px 0;
            text-align: center;
        }

        &__link-row {
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .link {
                flex: 1;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;

                &__text {
                    padding: 5px 5px;
                    font-family: 'Montserrat', sans-serif;
                    color: #777781;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 16px;
                    border-bottom: 2px solid transparent;
                    transition: border-bottom 0.2s;

                    @media only screen and (max-width: 300px) {
                        font-size: 13px;
                    }

                    &:hover {
                        cursor: pointer;
                        border-bottom: 2px solid #ffa626;
                    }
                }

                &__active {
                    border-bottom: 2px solid #ffa626;
                }
            }
        }

        &__row {
            flex: 1;
            display: flex;
            margin: 10px 0;

            &--is-not-row {
                display: block;

            }

            .input {
                align-items: center;
                justify-content: center;
                flex: 1;
                margin: 20px 0;
                text-align: center;
            }

            .pwd-match-txt {
                flex: 1;
                display: flex;
                margin-bottom: 10px;
            }
        }


        &__main-btn-row {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .register-icon {
            color: #777781;
            font-size: 20px;
        }

        .verified-text {
            font-size: 15px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 300;
            color: #777781;
            margin-left: 5px;
        }
    }


}