.client-inbox-page {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 800px) {
        display: block;
        position: relative;
    }

    .artist-list {
        height: calc(100vh - 60px - 1vh);
        width: 300px;
        background-color: #000000;
        background-image: linear-gradient(45deg, #000000 0%, #052034 100%);
        background-size: cover;
        background-position: center;
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        &__title {
            margin: 20px;
            text-align: center;
            font-weight: 300;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
            color: #fff
        }

        &__no-chats {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            font-weight: 300;
            color: #777781;
            text-align: center;
        }

    }


    .animated-artist-list-section-open {
        animation: showArtistListView 0.5s;
        z-index: 200;
        @media only screen and (max-width: 800px) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            max-width: 90vw;
            width: 300px;
        }

    }

    .animated-artist-list-section-close {
        width : 0;
        height: 0;
        animation: closeArtistListView 0.5s;
    }

    @keyframes showArtistListView {
        0% {
            width : 0;
            max-width: 0;
        }

        100% {
            width: 300px;
            max-width: 90vw;
        }
    }

    @keyframes closeArtistListView {
        0% {
            width:300px;
            max-width: 90vw;
        }

        100% {
            width: 0;
            max-width: 0;
        }
    }


    .detail-section {
        flex: 4;
        display: flex;
        flex-direction: row;
        @media only screen and (max-width: 1250px) {
            display: block;
            position: relative;
        }


        .message-section {
            height: calc(100vh - 60px - 1vh);
            box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);
            flex: 3;
            display: flex;
            flex-direction: column;

            &__no-messages {
                text-align: center;
                margin: auto;
                font-weight: 300;
                text-transform: uppercase;
                font-family: 'Montserrat', sans-serif;
                color: #777781
            }

            &__header {
                padding: 10px;
                display: flex;
                flex-direction: row;

                box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
                justify-content: space-between;

                &--left {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .avatar {
                        background-color: #ff9804;
                        color: #fff;
                        font-weight: 600;
                        text-transform: uppercase;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 15px;
                        margin-right: 5px;
                    }

                    .artist-name {
                        font-weight: 500;
                        text-transform: capitalize;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 16px;
                        margin-left: 5px;
                    }

                }

                &--right {}

            }

            &__main {
                flex: 1;
                display: flex;
                flex-direction: column-reverse;
                overflow-x: hidden;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    display: none;
                }

                .no-messages {
                    margin: auto;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 300;
                    text-transform: uppercase;
                    font-family: 'Montserrat', sans-serif;
                    color: #777781
                }
            }

            &__footer {
                box-shadow: 0 2px 3px 3px rgba(0, 0, 0, 0.1);
                padding: 10px;

                .text-box {
                    margin: auto;
                    text-align: center;
                    width: 60%;
                }
            }
        }

        .request-header-buttons {
            align-items: center;
            justify-self: flex-start;
            align-self: flex-start;
            display:  flex;
            flex-direction: row;
            justify-content: space-around;
            width: 100%;
            padding-top: 20px;
            &__button{
                font-family: 'Montserrat', sans-serif;
                color: #000;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 16px;
                border-bottom: 2px solid transparent;
                transition: border-bottom 0.2s;
                @media only screen and (max-width: 500px) {
                    font-size: 3.1vw;
                }
                &:hover{
                    border-bottom: 2px solid #ffa626;
                    cursor: pointer;
                }

                &.active{
                    border-bottom: 2px solid #ffa626;
                    cursor: pointer;
                }
                &--count{
                    font-size: 12px;
                    margin-left: 2px;
                    @media only screen and (max-width: 500px) {
                        font-size: 2.4vw;
                    }
                }
            }
        }
        .request-detail {
            flex: 1;
            display: flex;
            .mySwiper{
                z-index: 0;
              }
            & .event-request-swiper {
                padding: 0px 10px;
                width: 480px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                @media only screen and (max-width: 550px) {
                    width: 96vw;
                    padding: 0px 1vw;
                }
            }

            & .swiper-container {
                flex: 1;
            }

            & .swiper-slide {
                height: max-content;
                padding: 10px 20px;
                @media only screen and (max-width: 500px) {
                    padding: 10px 2vw;
                }
                /* Center slide text vertically */
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
            }
            & .empty-list {
                font-family: 'Montserrat', sans-serif;
                font-size: 16px;
                font-weight: 300;
                color: #777781;
                text-align: center;
            }
        }

        .animated-detail-section-open {
            animation: showDetailView 1s;
            flex: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media only screen and (max-width: 1250px) {
                position: absolute;
                background-color: #fff;
                top: 0;
                right: 0;
                height: calc(100vh - 60px - 1vh);
                width: 500px;
                box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);
                animation: showDetailViewAsDrawer 0.5s;
            }
            @media only screen and (max-width: 550px) {
                width: 100vw
            }
        }

        .animated-detail-section-close {
            animation: closeDetailView 1s;
            flex:0;
            @media only screen and (max-width: 1250px) {
                animation: closeDetailViewAsDrawer 0.5s;
            }
        }

        @keyframes showDetailView {
            0% {
                opacity: 0;
                max-width: 0;
                flex :0
            }

            100% {
                opacity: 1;
                flex: 2;
                max-width: 1000px;
            }
        }

        @keyframes closeDetailView {
            0% {
                opacity: 1;
                flex: 2;
                max-width: 1000px;
            }

            100% {
                opacity: 0;
                flex: 0;
                max-width: 0;
            }
        }

        @keyframes showDetailViewAsDrawer {
            0% {
               width:0;
               max-width: 0;
            }

            100% {
                width: 500px;
                max-width: 100vw;
            }
        }

        @keyframes closeDetailViewAsDrawer {
            0% {
               width:500px;
               max-width: 100vw;
            }

            100% {
                width: 0;
                max-width: 0;
            }
        }

    }
}