.artist-accept-popup-modal {
    background-color: #fff;
    width: 60%;
    max-height: 80%;
    z-index: 4;
    margin: 0 auto;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    @media only screen and (max-width: 1200px) {
        width: 75%;
        max-height: 85%;
    }

    @media only screen and (max-width: 1000px) {
        width: 85%;
        max-height: 90%;
    }

    @media only screen and (max-width: 600px) {
        width: 95%;
        max-height: 95%;
    }

    &::-webkit-scrollbar {
        display: none;
    }



    &__content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 20px 1.6vw;
        @media only screen and (max-width: 650px) {
            padding: 20px 10px;
        }

        &--top {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &--main {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-items: center;

            .condition {
                padding: 1.8vh 1.6vw;
                padding-top: 0px;

                .terms {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1vw;

                    @media only screen and (max-width: 1000px) {
                        font-size: 10px;
                    }

                    font-weight: 400;
                    color: #000;

                    .link {
                        font-family: 'Montserrat', sans-serif;
                        font-size: 1vw;

                        @media only screen and (max-width: 1000px) {
                            font-size: 10px;
                        }
                        font-weight: 500;
                        color: #000;
                        text-decoration: underline;
                        cursor: pointer;

                        &:hover {
                            color: #ff9804;
                        }
                    }


                }

                .disclaimer {
                    margin-top: 1.8vh;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 0.8vw;
                    @media only screen and (max-width: 1000px) {
                        font-size: 8px;
                    }
                    font-weight: 300;
                    font-style: italic;
                    color: #000;
                }
            }
        }


    }
}

.artist-accept-popup-top {
    &__title {
        padding: 1.8vh 1.6vw;
        text-align: center;
        &--text {
            text-align: center;
            font-size: 1.4vw;

            @media only screen and (max-width: 1200px) {
                font-size: 16px;
            }

            font-weight: 300;
            font-family: 'Montserrat',
            sans-serif;
        }

    }

    &__close-btn {
        align-self: flex-end;
    }
}

.artist-accept-popup-reason-section {
    padding-top: 10px;

    &__title {
        font-size: 15px;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        padding-bottom: 10px;
    }

    &__reasons {
        &--each {
            font-size: 14px;
            font-weight: 300;
            font-family: 'Montserrat', sans-serif;
        }
    }

    &__comment {
        text-align: center;
    }


}

.artist-accept-popup-button {
    margin-top: 3.6vh;
    margin-bottom: 1.8vh;
}