.dark-background {
    background-color: #000000;
    background-image: linear-gradient(215deg, #000000 0%, #052034 100%);
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    height: auto;
    min-width: fit-content;
}
.form-application{
    margin: 0 auto;
    width : 30%;
    border: 0.2px solid #777781;
    box-shadow: 0 0 10px #777781;
    padding: 10px 30px;
    color: #fff;
    min-width: 250px;
    max-width : 450px;
    @media only screen and (max-width: 1200px) {
        width : 40%;
    }
    @media only screen and (max-width: 1000px) {
        width : 50%;
    }
    @media only screen and (max-width: 600px) {
        width : 90%;
    }
}
.div-field-application{
    display: flex;
    margin: 30px auto;
    width: 80%;
    font-size: 80%;
    @media only screen and (max-width: 500px) {
        width : 95%;
    }

}
.div-field-application span{
    align-self: center;
}
.div-field-application .span-link{
    margin-left: 10px;
    margin-right: 20px;
    max-width: 80%;
    line-break: anywhere;
    flex:1;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 300;
}
.link-icon{
    align-self: center;
}
.apply-btn-view{
    width: 100%;
    padding: 30px;
    text-align: center;
}
