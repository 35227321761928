.client-rating-dark{
    background-color: #000000;
    background-image: linear-gradient(215deg, #000000 0%, #052034 100%);
    height: 100% ;
    min-height: 100vh;
}
.client-rating-page {
    min-width: 280px;
    width: 60%;
    margin: 20px auto;
    padding: 20px 20px;

    .title {
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
        text-align: center;
        color: #e3e3e3;
        margin: 10px 0px;

        .listing-details {

            &:hover {
                color: #ff9804;
                cursor: pointer;
            }
        }
        .event-details {

            &:hover {
                color: #ff9804;
                cursor: pointer;
            }
        }

        &__no-chats {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            font-weight: 300;
            text-transform: uppercase;
            color: #e3e3e3;
            text-align: center;
        }

    }

    .description {
        color: #777781;
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 300;
        text-align: center;
        margin: 10px 0px 40px;
    }

    .content {
        text-align: center;

        &__rating {
            margin: 10px 0px ;
            .text {
                font-family: 'Montserrat', sans-serif;
                font-size: 15px;
                font-weight: 300;
                color: #777781;
                margin: 5px 0px ;
                text-transform: uppercase;

            }
        }

        &__comment {
            margin: 20px 0px ;
            .text {
                font-family: 'Montserrat', sans-serif;
                font-size: 15px;
                font-weight: 400;
                color: #000;
                margin: 5px 0px ;
                text-align: center;

            }
            .text-field{
                min-width: 200px;
                width: 80%;
                margin: 10px auto;
            }
        }

    }

    .button{
        margin: 40px 0px;
        text-align: center;
    } 
    .already-rated-text{
        margin: 40px 0px;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 300;
        color: #e3e3e3;
    }  

}