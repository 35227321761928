.client-page {
  padding: 2vw;
  margin-top: 20px;

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;

    &--title {
      font-size: 2.4vw;
      font-weight: 600;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      padding-left: 10px;
      padding-right: 10px;

      @media only screen and (max-width: 800px) {
        font-size: 22px;
      }
    }
  }

  &__content {
    width: 55%;
    max-width: 800px;
    min-width: 250px;

    @media only screen and (max-width: 1000px) {
      width: 70%
    }

    @media only screen and (max-width: 750px) {
      width: 80%
    }

    @media only screen and (max-width: 500px) {
      width: 90%
    }


    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &--title {
      font-size: 18px;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
    }

    &--form {
      flex: 1;
      width: 100%;
      margin: 20px 0;
      display: flex;
      flex-direction: column;

      &_row {
        flex: 1;
        display: flex;
        margin: 10px 0;
        align-items: flex-end;

        @media only screen and (max-width: 700px) {
          display: block;
        }

        .whatsapp-status{
          flex :1;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
            .icon{
              font-size: 14px;
              margin-right: 5px;
            }
            .text{
              font-size: 10px;
            }
        }
      }
    }

  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    &--card {
      min-width: 250px;
      flex: 1;
      padding: 20px;
    }
  }


}

.register-icon {
  color: #777781;
  font-size: 20px;
}

.verified-text {
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: #777781;
  margin-left: 5px;
}