.artist-page {
  padding: 2vw;
  margin-top: 20px;

  .block-row {
    display: flex;

    @media only screen and (max-width: 1100px) {
      display: block;
    }
  }

  .details-block {
    flex: 2;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;

    &--title {
      font-size: 2.4vw;
      font-weight: 600;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      padding-left: 10px;
      padding-right: 10px;

      @media only screen and (max-width: 800px) {
        font-size: 22px;
      }

    }
  }

  &__content {
    width: 75%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 700px) {
      width: 95%;
    }

    &--title {
      font-size: 18px;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
    }

    &--form {
      flex: 1;
      width: 100%;
      margin: 20px 0;
      display: flex;
      flex-direction: column;

      &_row {
        flex: 1;
        display: flex;
        margin: 10px 0;
        align-items: flex-end;

        @media only screen and (max-width: 700px) {
          display: block;
        }

        .whatsapp-status{
          flex :1;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
            .icon{
              font-size: 14px;
              margin-right: 5px;
            }
            .text{
              font-size: 10px;
            }
        }
      }
    }

  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    &--card {
      min-width: 250px;
      flex: 1;
      padding: 20px;
    }
  }

  &__listing-block {
    flex: 1;
    max-width: 33vw;
    border-left-width: 1px;
    @media only screen and (max-width: 1100px) {
      border-left-width: 0px;
      min-width: 200px;
      margin: 0 auto;
      max-width: 500px;
    }

    & .listing-swiper {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 1100px) {
        padding: 20px 5px;
      }
    }

    .mySwiper {
      z-index: 0;
    }

    & .swiper-container {
      flex: 1;
    }

    & .swiper-slide {
      height: auto;
      padding: 20px;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      @media only screen and (max-width: 1100px) {
        padding: 20px 5px;
      }
    }

    & .add-listing {
      padding-left: 65px;
      padding-right: 30px;
      text-align: left;
      @media only screen and (max-width: 1100px) {
        padding: 0 30px;
        text-align: center;
      }
    }

  }



}

.register-icon {
  color: #777781;
  font-size: 20px;
}

.verified-text {
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: #777781;
  margin-left: 5px;
}