.notification-wrapper {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 90%;
    max-width: 400px;
    z-index: 2080;
}

.notification-item {
    box-shadow: 0 0 10px rgba(0,0,0, 0.3);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    animation: SlideLeft 0.4s;
    animation-fill-mode: forwards;
    width: 100%;
}

@keyframes SlideLeft {
    0% {
        margin-left: 120%;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes SlideRight {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 120%;
    }
}

.notification-item.exit {
    animation: SlideRight 0.4s;
    animation-fill-mode: forwards;
}

.notification-item p {
    margin: 0;
    padding: 3px 10px;
}

.notification-item .bar {
    height: 2px;
}

.notification-item.success .bar {
    background-color: #ffffff;
}

.notification-item.error .bar {
    background-color: #ffffff;
}

.notification-title{
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
}

.notification-message{
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    font-weight: 200;
    color: #ffffff;
    font-size: 12px;
}

.notification-item.error{
    background-color: rgba(118, 15, 24,1);
}

.notification-item.success {
    background-color: rgba(15, 67, 16,1);
}
