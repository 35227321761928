.sign-background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 1);
    background-image: linear-gradient(215deg, rgba(0, 0, 0, 0), #052034 100%);
    z-index: 10;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    .main-box {
        padding: 30px;
        background-color: #000000;
        background-image: linear-gradient(215deg, #000000 0%, #052034 100%);
        border: 0.2px solid #777781;
        box-shadow: 0 0 10px #777781;
        padding: 0px 3vw;
        min-width: 250px;
        max-width: 450px;
        width: 30%;
        display: flex;
        flex-direction: column;
        @media only screen and (max-width: 1200px) {
            width : 40%;
        }
        @media only screen and (max-width: 1000px) {
            width : 50%;
        }
        @media only screen and (max-width: 600px) {
            width : 90%;
        }

        &__btn-row {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            margin: 30px 0;
            text-align: center;
        }

        &__row {
            align-items: center;
            justify-content: center;
            flex: 1;
            margin: 30px auto 0;
            text-align: center;
            width: 70%;
            @media only screen and (max-width: 1000px) {
                width : 80%;
            }
            @media only screen and (max-width: 500px) {
                width : 90%;
            }

        }

        &__frgt-pwd {
            text-align: center;
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__main-btn-row {
            text-align: center;
            margin-top: 60px;
            margin-bottom: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .show-password-section {
            animation: showPasswordView 1s;

        }

        .hide-password-section {
            animation: hidePasswordView 1s;
            height: 0;
        }

        @keyframes showPasswordView {
            0% {
                opacity: 0;
                max-height: 0;
            }

            100% {
                opacity: 1;
                max-height: 1500px;
            }
        }

        @keyframes hidePasswordView {
            0% {
                opacity: 1;
                height: 100px;
            }

            100% {
                opacity: 0;
                height: 0;
            }
        }


    }


}