.client-template-popup-modal {
    background-color: #fff;
    width: 80%;
    height: 90%;
    z-index: 100;
    margin: 0 auto;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    @media only screen and (max-width: 1000px) {
        width: 85%;
        max-height: 90%;
    }

    @media only screen and (max-width: 750px) {
        width: 95%;
        max-height: 95%;
    }

    &::-webkit-scrollbar {
        display: none;
    }



    &__content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 20px 1.6vw;

        @media only screen and (max-width: 650px) {
            padding: 20px 10px;
        }

        &--top {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &--main {
            margin-top: 10px;

            .main-detail {

                &--row {
                    padding: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;

                    @media only screen and (max-width: 750px) {
                        display: block;
                        padding: 0 4vw;
                    }


                    &__element {
                        flex: 1;
                        text-align: center;
                        padding-top: 15px;
                        padding-bottom: 15px;


                        &--icon {
                            flex: 1;
                        }

                        .total-price {
                            font-family: 'Montserrat', sans-serif;
                            font-size: 25px;
                            font-weight: 400;
                            color: #ff9804;
                        }
                    }

                    &__title-text {
                        width: 75%;

                        @media only screen and (max-width: 750px) {
                            width: 100%;

                        }

                        margin: auto;
                        font-family: 'Montserrat',
                        sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        color: #000;
                    }
                }

                .price-text-field {
                    width: 40%;
                    min-width: 200px;
                    margin: auto;
                    text-align: center;
                    padding: 10px;
                }

                .event-name {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 20px;
                    font-weight: 300;
                    text-transform: capitalize;
                    text-align: center;
                    border-bottom-width: 1px;
                    border-bottom-color: #e3e3e3;
                    padding-bottom: 5px;
                }

                .event-between-detail {
                    display: flex;
                    justify-content: center;
                    padding: 5px;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 15px;
                    font-weight: 400;
                    text-transform: capitalize;
                    text-align: center;


                }

                .date {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 15;
                    font-weight: 300;
                    text-transform: capitalize;
                    text-align: center;
                    color: #777781;
                }
            }
        }

    }
}

.client-template-popup-top {
    &__title {
        text-align: center;
        font-size: 1.7vw;
        word-break: break-all;

        @media only screen and (max-width: 900px) {
            font-size: 16px;
        }

        font-weight: 700;
        text-transform: uppercase;
        font-family: 'Montserrat',
        sans-serif;
        padding: 20px;
        display: flex;
        justify-content: center;

        .client-details {
            margin-left: 10px;
            margin-right: 10px
        }

        .listing-details {
            margin-left: 10px;
            margin-right: 10px;

            &:hover {
                color: #ff9804;
                cursor: pointer;
            }

            .insured {
                display: inline-block;
            }
        }

    }

    &__close-btn {
        align-self: flex-end;
    }
}

.client-template-popup-country {
    &__section {
        margin-top: 30px;

        &--title {
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
            padding: 16px;

        }

        &--logos {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}

.client-template-view {
    flex: 0 0 calc(25% - 20px);
    padding: 20px;
}