.artist-sign-up-page {

    .heading {
        padding: 15px;

        &__title {
            font-size: 2.2vw;
            font-weight: 400;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
            text-align: center;
            margin-bottom: 10px;

            @media only screen and (max-width: 900px) {
                font-size: 22px;
            }
        }

        &__description {
            font-size: 15px;
            font-weight: 300;
            font-family: 'Montserrat', sans-serif;
            text-align: center;
        }

    }

    .features {
        display: flex;
        flex-direction: row;
        padding: 10px 5vw;
        flex-wrap: wrap;

        &__feature {
            min-width: 200px;
            flex: 1;
            padding: 10px 20px;

            &--title {
                font-size: 20px;
                font-weight: 300;
                font-family: 'Montserrat', sans-serif;
                text-align: center;
                color: black;
                padding-bottom: 5px;
            }

            &--description {
                font-size: 14px;
                font-weight: 200;
                font-family: 'Montserrat', sans-serif;
                text-align: center;
            }

            &--number {
                text-align: center;
                font-size: 20px;
                font-weight: 500;
                font-family: 'Montserrat', sans-serif;
                color: black;
            }
        }

    }

    .apply-btn {
        text-align: center;
        margin: 10px 0;
    }

    .benefit-section {
        margin: 60px 3vw 20px 3vw;
        padding-top: 10px;
        border: solid 1px #ffa626;
        border-radius: 2px;

        &__title {
            font-size: 22px;
            font-weight: 300;
            font-family: 'Montserrat', sans-serif;
            text-transform: uppercase;
            text-align: center;
            color: black;
        }

        .benefits {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 10px 3vw;

            &__benefit {
                min-width: 150px;
                flex: 1;
                padding: 20px;
                text-align: center;

                &--icon {
                    text-align: center;
                }

                &--title {
                    font-size: 20px;
                    font-weight: 300;
                    font-family: 'Montserrat', sans-serif;
                    text-align: center;
                    color: black;
                    padding-bottom: 5px;
                }

                &--description {
                    font-size: 14px;
                    font-weight: 200;
                    font-family: 'Montserrat', sans-serif;
                    text-align: center;
                }

            }
        }
    }
}