.wishlist-page {
    padding: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    .request-section {
        flex: 5;
        max-width: 83vw;
        padding: 10px 40px;

        .short-list {

            &__title {

                font-size: 20px;
                font-weight: 600;
                text-transform: uppercase;
                font-family: 'Montserrat', sans-serif;
            }

            & .no-listing-shortlist {
                font-family: 'Montserrat', sans-serif;
                font-size: 16px;
                font-weight: 300;
                color: #777781;
                text-align: center;
            }

            & .short-list-swiper {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            & .swiper-container {
                flex: 1;
            }

            & .swiper-slide {
                height: auto;
                padding: 20px;
                /* Center slide text vertically */
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
            }


        }

        .request-form {
            padding: 10px;
            width: 100%;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            height: fit-content;
            border: 1px solid rgb(227, 227, 227);
            border-radius: 2px;

            &__availability {
                &--row {
                    padding: 5px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;

                    &__element {
                        flex: 1;

                    }
                }
            }

            &__templates-section {
                padding: 10px;

                &--heading {
                    display: flex;
                    padding: 10px;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    .text {
                        font-family: 'Montserrat', sans-serif;
                        font-size: 13px;
                        font-weight: 300;
                        text-transform: uppercase;
                        margin-right: 10px;
                    }
                }

                .template-show-animate {
                    animation: showTemplateView 1s;

                }

                .template-hide-animate {
                    animation: hideTemplateView 1s;
                }


                @keyframes showTemplateView {
                    0% {
                        opacity: 0;
                        max-height: 0px;
                    }

                    100% {
                        opacity: 1;
                        max-height: 100px;
                    }
                }

                @keyframes hideTemplateView {
                    0% {
                        opacity: 1;
                        height: 50px;
                    }

                    100% {
                        opacity: 0;
                        height: 0;
                    }
                }


                &--row {
                    padding: 5px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    width: 60%;
                    margin: auto;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;

                    &__element {
                        flex: 1;
                        text-align: center;

                        &--icon {
                            flex: 1;
                        }
                    }

                    &__btn {
                        padding-left: 20px;
                    }
                }

            }

            &__request {
                &--residency {
                    padding: 5px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    width: 90%;
                    margin: 0 auto;

                    .timings-section {
                        padding: 10px;
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                        border: 1px solid rgb(227, 227, 227);
                        border-radius: 2px;
                        max-height: 30vh;
                        overflow-x: hidden;
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        &__title {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 5px;

                            &--text {
                                font-family: 'Montserrat', sans-serif;
                                font-size: 16px;
                                font-weight: 400;
                                color: #000;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .error-icon {
                                    margin-left: 10px;
                                }
                            }
                        }

                        &__no-timings {
                            text-align: center;
                            padding: 10px;
                            font-family: 'Montserrat', sans-serif;
                            font-size: 15px;
                            font-weight: 500;
                            color: #777781;
                        }

                        &__timings {
                            justify-content: center;
                            display: grid;
                            padding: 5px 0;
                            grid-template-columns: repeat(auto-fill, calc(max(50%, 250px)));

                            .left-border {
                                border-right: solid 1px #e3e3e3;
                            }

                            &--single {
                                padding: 0 10px;
                                display: flex;
                                align-items: center;


                                .date {
                                    flex: 1;
                                    font-family: 'Montserrat', sans-serif;
                                    font-size: 13px;
                                    font-weight: 300;
                                }

                                .time {
                                    flex: 1.5;
                                    font-family: 'Montserrat', sans-serif;
                                    font-size: 13px;
                                    font-weight: 300;
                                    text-align: right;

                                    .hours {
                                        font-family: 'Montserrat', sans-serif;
                                        font-size: 10px;
                                        font-weight: 300;
                                    }
                                }


                                .delete {
                                    flex: 0.5;
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                }

                            }
                        }
                    }

                    .calendar-section {
                        width: calc(max(60%, 250px));
                        margin: 20px auto;
                    }

                    .edit-section {
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                        height: fit-content;
                        border: 1px solid rgb(227, 227, 227);
                        border-radius: 2px;
                        padding: 5px 0;

                        &__row {
                            display: flex;
                            padding: 5px;
                            padding-top: 15px;
                            padding-bottom: 10px;
                            flex-direction: row;
                            align-items: center;

                            &--element {
                                flex: 1;
                                text-align: center;

                                .date {
                                    font-family: 'Montserrat', sans-serif;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #000;

                                }
                            }
                        }
                    }
                }

                &--row {
                    padding: 5px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;

                    &__element {
                        flex: 1;
                        text-align: center;

                        &--icon {
                            flex: 1;
                        }

                        .total-price {
                            font-family: 'Montserrat', sans-serif;
                            font-size: 25px;
                            font-weight: 400;
                            color: #ff9804;
                        }
                    }

                    &__title-text {
                        width: 80%;
                        margin: auto;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        color: #000;
                    }

                    .price-text-field {
                        width: 40%;
                        min-width: 200px;
                        margin: auto;
                        text-align: center;
                        padding: 10px;
                    }
                }

                &--template-save {
                    padding: 5px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    text-align: center;

                    .show-template-name {
                        animation: showTemplateName 1s;

                    }

                    .hide-template-name {
                        animation: hideTemplateName 1s;
                    }


                    @keyframes showTemplateName {
                        0% {
                            opacity: 0;
                            max-height: 0px;
                        }

                        100% {
                            opacity: 1;
                            max-height: 50px;
                        }
                    }

                    @keyframes hideTemplateName {
                        0% {
                            opacity: 1;
                            height: 50px;
                        }

                        100% {
                            opacity: 0;
                            height: 0;
                        }
                    }

                }
            }


        }
    }

    .wishlist-section {
        flex: 1;
        padding: 10px;
        padding-left: 20px;
        border-left-width: 1px;

        &__title {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
        }

        &__listings {
            margin-top: 20px;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .listing {
                margin-bottom: 20px;
            }

            .no-listing-message {
                font-family: 'Montserrat', sans-serif;
                font-size: 16px;
                font-weight: 300;
                color: #777781;
                text-align: center;
            }
        }
    }

    .animatedShowShortList {
        animation: showShortListView 1s;
        max-height: 500;

    }

    .animatedHideShortList {
        animation: hideShortListView 1s;
        height: 0;
    }

    @keyframes showShortListView {
        0% {
            opacity: 0;
            max-height: 0;
        }

        100% {
            opacity: 1;
            max-height: 500px;
        }
    }

    @keyframes hideShortListView {
        0% {
            opacity: 1;
            height: 100px;
        }

        100% {
            opacity: 0;
            height: 0;
        }
    }


    .animatedShowRequest {
        animation: showRequestView 1s;
        max-height: 500;

    }

    .animatedHideRequest {
        animation: hideRequestView 1s;
        height: 0;
    }

    @keyframes showRequestView {
        0% {
            opacity: 0;
            max-height: 0;
        }

        100% {
            opacity: 1;
            max-height: 1500px;
        }
    }

    @keyframes hideRequestView {
        0% {
            opacity: 1;
            height: 100px;
        }

        100% {
            opacity: 0;
            height: 0;
        }
    }


}