.client-event-request-book-popup-modal {
    background-color: #000000;
    background-image: linear-gradient(215deg, #000000 0%, #052034 100%);
    width: 75%;
    max-height: 90%;
    z-index: 4;
    margin: 0 auto;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    @media only screen and (max-width: 1300px) {
        width: 85%;
        max-height: 90%;
    }

    @media only screen and (max-width: 1000px) {
        width: 90%;
        max-height: 90%;
    }

    @media only screen and (max-width: 850px) {
        width: 95%;
        max-height: 95%;
    }



    &__content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 20px 1.6vw;

        @media only screen and (max-width: 650px) {
            padding: 20px 10px;
        }

        &--top {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &--main {

            display: flex;
            flex-direction: row-reverse;

            @media only screen and (max-width: 650px) {
                display: block;
            }

            .left-side {
                flex: 1;
                padding: 0px 2.4vw;
                border-right: solid 1px #36363a;

                @media only screen and (max-width: 650px) {
                    padding: 0px 10px;
                    border-right: none;
                    margin-top: 20px;
                }


                &__title {
                    display: flex;
                    align-items: center;
                    &--text{
                        font-size: 18px;
                        font-weight: 200;
                        font-family: 'Montserrat', sans-serif;
                        margin-bottom: 5px;
                        color: #fff;
                    }

                    &--btn{

                    }
                    

                }
                .show-booking {
                    opacity: 1;
                    max-height: unset;
                    animation-name: showBooking;
                    animation-duration: 1s;
                }

                @keyframes showBooking {
                    0% {
                        max-height: 0px;
                        opacity: 0;
                    }

                    100% {
                        max-height: 1000px;
                        opacity: 1;

                    }
                }

                .hide-booking {
                    opacity: 1;
                    height: 0px;
                    animation-name: hidebooking;
                    animation-duration: 1s;
                }

                @keyframes hidebooking {
                    0% {
                        opacity: 0;
                        height: 50px;
                    }

                    100% {
                        opacity: 1;
                        height: 0px;
                    }
                }
            }

            .right-side {
                flex: 1;
                padding: 0px 2.4vw;

                @media only screen and (max-width: 650px) {
                    padding: 0px 10px;
                }

                .payment-terms {
                    margin-bottom: 50px;

                    &__title {
                        font-size: 18px;
                        font-weight: 200;
                        font-family: 'Montserrat', sans-serif;
                        margin-bottom: 5px;
                        color: #fff;
                    }

                    &__content {
                        &--main {
                            font-size: 11px;
                            font-weight: 300;
                            font-family: 'Montserrat', sans-serif;
                            margin-bottom: 10px;
                            color: #e3e3e3;

                            .link {
                                font-weight: 400;
                                cursor: pointer;

                                &:hover {
                                    color: #ff9804;
                                }
                            }
                        }

                        &--italic {
                            font-size: 11px;
                            font-weight: 300;
                            font-style: italic;
                            font-family: 'Montserrat', sans-serif;
                            margin-bottom: 10px;
                            color: #e3e3e3;
                        }

                        &--terms {
                            margin-bottom: 8px;

                            .terms-heading {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                margin-bottom: 5px;

                                &__text {
                                    font-size: 11px;
                                    font-weight: 400;
                                    font-family: 'Montserrat', sans-serif;

                                    color: #e3e3e3;
                                }
                            }

                            .terms-points {
                                font-size: 11px;
                                font-weight: 200;
                                font-family: 'Montserrat', sans-serif;
                                margin-bottom: 5px;
                                color: #e3e3e3;
                            }
                        }

                    }
                }

                .payment-mode {
                    margin-bottom: 50px;
                }

                .direct {
                    font-size: 11px;
                    font-weight: 300;
                    font-style: italic;
                    font-family: 'Montserrat', sans-serif;
                    margin-bottom: 10px;
                    color: #e3e3e3;
                }

                .error {
                    font-size: 11px;
                    font-weight: 300;
                    font-style: italic;
                    font-family: 'Montserrat', sans-serif;
                    margin-bottom: 10px;
                    color: #D11A2A;
                }

                .card {
                    &__title {
                        font-size: 18px;
                        font-weight: 200;
                        font-family: 'Montserrat', sans-serif;
                        margin-bottom: 10px;
                        color: #fff;
                    }

                    &__detail {

                        &--row {
                            display: flex;
                            flex-direction: row;
                            border: solid 1px;
                            border-radius: 2px;
                            transition: border-color 500ms;

                            &:first-child {
                                border-bottom: none;
                            }

                            &__element {
                                flex: 1;
                                padding: 10px;
                                border-left: solid 1px;
                                transition: border-color 500ms;

                                &:first-child {
                                    border-left: none;
                                }

                            }
                        }
                    }

                }

                .pay-button {
                    margin-top: 25px;
                    margin-bottom: 10px;
                    text-align: center;
                }

                .show-terms-animation {
                    opacity: 1;
                    max-height: unset;
                    animation-name: showTermsAnimate;
                    animation-duration: 1s;
                }

                @keyframes showTermsAnimate {
                    0% {
                        max-height: 0px;
                        opacity: 0;
                    }

                    100% {
                        max-height: 1000px;
                        opacity: 1;

                    }
                }

                .hide-terms-animation {
                    opacity: 1;
                    height: 0px;
                    animation-name: hideTermsAnimate;
                    animation-duration: 1s;
                }

                @keyframes hideTermsAnimate {
                    0% {
                        opacity: 0;
                        height: 50px;
                    }

                    100% {
                        opacity: 1;
                        height: 0px;
                    }
                }
            }
        }


    }
}

.client-event-request-book-popup-top {
    &__title {
        &--text {
            text-align: center;
            font-size: 20px;
            font-weight: 300;
            font-family: 'Montserrat', sans-serif;
            padding: 20px;
        }

    }

    &__close-btn {
        align-self: flex-end;
    }
}


.client-event-request-book-popup-button {
    margin-top: 20px;
    margin-bottom: 20px;
}