.collage-image {
    opacity: 1;
    transform: scale(1);
    transition: transform 0.5s, opacity 0.5s;
    cursor: pointer;
}


.collage-image:hover,
.collage-image:focus {
    opacity: 0.4;
    transform: scale(1.05);
}

.collage-image-text {
    visibility: hidden;
    pointer-events: none;
    
}

.collage-image:hover~.collage-image-text {
    visibility: visible;

}

.collage-image-text:hover~ {
    visibility: visible;
}