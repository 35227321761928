.search-page {
    padding: 6vh 6vw;
    background-color: #fff;

    &__top-section {
        margin-bottom: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        &--title {
            font-family: 'Montserrat', sans-serif;
            font-size: 40px;
            font-weight: 500;
        }

        &--count {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            font-weight: 300;
            color: #777781;
            margin-bottom: 20px;
        }

        &--more-filter-btn {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &--filter {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 30px;
            margin-bottom: 30px;
            align-items: center;

            .filter-section {
                flex: 1;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
    }

    &__main-section {
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(auto-fill,calc(max(25%, 250px)));

        &--listing {
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 10px;
        }
    }

    &__paging-section {
        padding-top: 50px;
        padding-bottom: 20px;

        & .MuiPagination-ul {
            align-items: center;
            justify-content: center;


            & .MuiPaginationItem-root {
                font-family: 'Montserrat', sans-serif;
                font-size: 15px;
                font-weight: 300;
            }

            & .MuiPaginationItem-page.Mui-selected {
                background-color: #ffa626;
                color: #fff;
            }
        }
    }


    .show-filter-animation {
        opacity: 1;
        max-height: unset;
        animation-name: showFilterAnimate;
        animation-duration: 1.5s;
    }

    @keyframes showFilterAnimate {
        0% {
            max-height: 32px;
            opacity: 0;
        }

        100% {
            max-height: 300px;
            opacity: 1;

        }
    }

    .hide-filter-animation {
        opacity: 1;
        max-height: unset;
        animation-name: hideFilterAnimate;
        animation-duration: 1.5s;
    }

    @keyframes hideFilterAnimate {
        0% {
            opacity: 0;
            max-height: 300px;
            height: 60px;
        }

        100% {
            opacity: 1;
            max-height: 34px;
            height: 34px;
        }
    }


}