.whatsapp-update-popup-modal {
    background-color: #fff;
    width: 60%;
    max-height: 80%;
    z-index: 4;
    margin: 0 auto;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    @media only screen and (max-width: 1200px) {
        width: 80%;
    }

    @media only screen and (max-width: 1000px) {
        width: 90%;
    }

    @media only screen and (max-width: 600px) {
        width: 90%;
        max-height: 90%;
    }


    &::-webkit-scrollbar {
        display: none;
    }



    &__content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 20px 1.6vw;

        @media only screen and (max-width: 650px) {
            padding: 20px 10px;
        }

        &--top {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &--main {}

        &--row {
            flex: 1;
            display: flex;
            margin: 10px 2vw;

            &--is-not-row {
                display: block;

            }

            &__text {
                font-size: 14px;
                font-family: 'Montserrat', sans-serif;
                font-weight: 300;
                margin: 10px 0;
            }

            &__check{
                margin-left: 10px;
                margin: 10px 10px;
            }

            .input {
                align-items: center;
                justify-content: center;
                flex: 1;
                margin: 20px 0;
                text-align: center;
            }

            .pwd-match-txt {
                flex: 1;
                display: flex;
                margin-bottom: 10px;
            }
        }


        &__main-btn-row {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .register-icon {
            color: #777781;
            font-size: 20px;
        }

        .verified-text {
            font-size: 15px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 300;
            color: #777781;
            margin-left: 5px;
        }


    }
}

.whatsapp-update-popup-top {
    &__title {
        padding: 1.8vh 1.6vw;
        text-align: center;

        &--text {
            text-align: center;
            display: flex;
            align-items: center;
            font-size: 1.4vw;
            margin: 1.8vh 1.6vw;

            .icon {
                font-size: 1.6vw;
                margin-right: 5px;

                @media only screen and (max-width: 1200px) {
                    font-size: 18px;
                }
            }

            @media only screen and (max-width: 1200px) {
                font-size: 16px;
            }

            font-weight: 300;
            font-family: 'Montserrat',
            sans-serif;
        }

    }

    &__close-btn {
        align-self: flex-end;
    }
}

.whatsapp-update-popup-reason-section {
    padding-top: 10px;

    &__title {
        font-size: 15px;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        padding-bottom: 10px;
    }

    &__reasons {
        &--each {
            font-size: 1vw;

            @media only screen and (max-width: 1000px) {
                font-size: 10px;
            }

            font-weight: 300;
            font-family: 'Montserrat',
            sans-serif;
        }
    }

    &__comment {
        text-align: center;
    }


}

.whatsapp-update-popup-button {
    margin-top: 5vh;
    margin-bottom: 5vh;
}