.alert-popup-modal {
    background-color: #fff;
    width: 60%;
    max-height: 80%;
    z-index: 4;
    margin: 0 auto;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    @media only screen and (max-width: 1200px) {
        width: 75%;
        max-height: 85%;
    }

    @media only screen and (max-width: 1000px) {
        width: 85%;
        max-height: 90%;
    }

    @media only screen and (max-width: 600px) {
        width: 95%;
        max-height: 95%;
    }

    &::-webkit-scrollbar {
        display: none;
    }



    &__content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-around;
         padding: 20px 1.6vw;
        @media only screen and (max-width: 650px) {
            padding: 20px 10px;
        }

        &--top {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &--main {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-items: center;
        }


    }
}

.alert-popup-top {
    &__title {
        padding: 1.8vh 1.6vw;
        text-align: center;
        &--text {
            text-align: center;
            font-size: calc(min(1.4vw, 20px));

            @media only screen and (max-width: 1200px) {
                font-size: 16px;
            }
            font-weight: 300;
            font-family: 'Montserrat', sans-serif;
        }

    }

    &__close-btn {
        align-self: flex-end;
    }
}

.alert-popup-reason-section {
    padding-top: 10px;
   
    &__title {
        font-size: 15px;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        padding-bottom: 10px;
    }

    &__reasons {
        &--each{
            font-size: 1vw;

            @media only screen and (max-width: 1000px) {
                font-size: 10px;
            }
            font-weight: 300;
            font-family: 'Montserrat', sans-serif;
        }      
    }

    &__comment{
        text-align: center;
    }


}

.alert-popup-button{
    margin-top: 3.6vh;
    margin-bottom: 1.8vh;
}
