/* ------------------------------------------------ */
/* BASIC SETUP */
/* ------------------------------------------------ */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: #ffffff;
  color: #555;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 20px;
  text-rendering: optimizeLegibility;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: " -";
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}

/* ------------------------------------------------ */
/* REUSABLE COMPONENTS */
/* ------------------------------------------------ */

.row {
  padding: 0 50px;
  margin: 0 auto;
  display: block;
}

section {
  padding: 20px 0;
}

.box {
  padding: 1%;
}

/* ----------HEADINGS-------------*/

h1,
h2,
h3 {
  font-weight: 400;
  letter-spacing: 1px;
}

h1 {
  font-size: 240%;
  font-weight: bold;
  word-spacing: 4px;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
}

h2 {
  font-size: 180%;
  font-weight: bold;
  word-spacing: 2px;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
}

h3 {
  font-size: 110%;
  font-family: "Montserrat", sans-serif;
}

/* ----------PARAGRAPH-------------*/
p {
  font-size: 90%;
  line-height: 145%;
  font-family: 'Montserrat', sans-serif;
}



.main-btn {
  //padding: 10px 30px;
  color: #ffa626;
  background-color: transparent;
  border: 1px solid #ffa626;
  border-radius: 10px;
  text-transform: uppercase;
  outline: none;
}

.main-btn:hover {
  background-color: #ffa626;
  color: #fff;
}

.svg-icon {
  border-radius: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.svg-icon:hover {
  border: 1px solid #ffa626;
  fill: #ffa626;
}

.delete-btn {
  color: rgba(0, 0, 0, .6);
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, .6);
  border-radius: 10px;
  text-transform: uppercase;
  outline: none;
}

.delete-btn:hover {
  color: #fff;
  background-color: #ffa626;
  border-color: #ffa626;
}

.modal-bg {
  background-color: rgba(7, 7, 7, 0.4);
  padding: 30px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  bottom: 0;
}

p {
  font-size: 90%;
  line-height: 145%;
  font-family: "Montserrat", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.aspectratio {
  width: 100%;
  height: auto;
}

@supports not (aspect-ratio: 4 / 3) {
  .aspectratio {
    position: relative;
    height: 0;
    width: 100%;
  }

  .ar16-9 {
    padding-top: 56.25%;
  }

  .ar4-3 {
    padding-top: 75%;
  }

  .ar3-1 {
    padding-top: 33.33%;
  }

  .ar1-1 {
    padding-top: 100%;
  }

  .aspectratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.file-data {
  font-family: 'Montserrat', sans-serif;
  color: black;
  font-size: 15px;

  .content {
    padding: 6vh 8vw;
  }

  ul,
  ol {
    list-style: unset;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 15px;
  }

  h1 {
    font-weight: 400;
    font-size: 50px;
    word-spacing: 0px;
    letter-spacing: 0px;
    font-family: "Montserrat", sans-serif;

  }

  h2 {
    font-weight: 400;
    font-size: 40px;
    word-spacing: 0px;
    letter-spacing: 0px;
    font-family: "Montserrat", sans-serif;
  }

  h3 {
    font-weight: 400;
    font-size: 35px;
    font-family: "Montserrat", sans-serif;
  }

  h4 {
    font-weight: 400;
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
  }

  h5 {
    font-weight: 400;
    font-size: 25px;
    font-family: "Montserrat", sans-serif;
  }

  h6 {
    font-weight: 400;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
  }


  /* ----------PARAGRAPH-------------*/
  p {
    font-weight: 400;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
  }

  blockquote {
    background: #fff;
    border-left: .7rem solid #007bff;
    margin: 1.5em .7rem;
    padding: .5em .7rem;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
  }

  pre {
    padding: .75rem;
    display: block;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    color: #212529;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;

  }
}