.create-booking-link-page{
    min-height: 100vh;
    width: auto;
    height: 100%; 

    &__content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 20px 1.6vw;

        @media only screen and (max-width: 650px) {
            padding: 20px 10px;
        }

        &--top {
            display: flex;
            flex-direction: column;
            align-items: center;
            &__title {
                text-align: center;
                font-size: 1.7vw;
                word-break: break-all;
        
                @media only screen and (max-width: 900px) {
                    font-size: 16px;
                }
        
                font-weight: 700;
                text-transform: uppercase;
                font-family: 'Montserrat',
                sans-serif;
                padding: 20px;
                display: flex;
                justify-content: center;
        
                .client-details {
                    margin-left: 10px;
                    margin-right: 10px
                }
        
                .listing-details {
                    margin-left: 10px;
                    margin-right: 10px;
        
                    &:hover {
                        color: #ff9804;
                        cursor: pointer;
                    }
        
                    .insured {
                        display: inline-block;
                    }
                }
        
            }
        
            &__close-btn {
                align-self: flex-end;
            }
        }

        &--main {
            margin-top: 10px;

            .main-detail {
                &--residency-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    @media only screen and (max-width: 500px) {
                        align-items: flex-start;
                   }

                    &__element {
                        flex: 1;
                        text-align: center;
                        padding-top: 15px;
                        padding-bottom: 15px;
                    }
                }

                &--residency {
                    padding: 5px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    width: 90%;
                    @media only screen and (max-width: 650px) {
                         width: 100%;
                    }
                    margin: 0 auto;

                    .timings-section {
                        padding: 10px;
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                        border: 1px solid rgb(227, 227, 227);
                        border-radius: 2px;
                        max-height: 30vh;
                        overflow-x: hidden;
                        overflow-y: auto;
                        @media only screen and (max-width: 650px) {
                            padding: 5px;
                       }

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        &__title {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 5px;

                            &--text {
                                font-family: 'Montserrat', sans-serif;
                                font-size: 16px;
                                font-weight: 400;
                                color: #000;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .error-icon {
                                    margin-left: 10px;
                                }
                            }
                        }

                        &__no-timings {
                            text-align: center;
                            padding: 10px;
                            font-family: 'Montserrat', sans-serif;
                            font-size: 15px;
                            font-weight: 500;
                            color: #777781;
                        }

                        &__timings {
                            justify-content: center;
                            display: grid;
                            padding: 5px 0;
                            grid-template-columns: repeat(auto-fill, calc(max(50%, 250px)));
                            @media only screen and (max-width: 650px) {
                                display:block;
                           }
                            .left-border {
                                
                                border-right: solid 1px #e3e3e3;
                                @media only screen and (max-width: 650px) {
                                    border-right: none;
                               }
                            }

                            &--single {
                                padding: 0 10px;
                                display: flex;
                                align-items: center;


                                .date {
                                    flex: 1;
                                    font-family: 'Montserrat', sans-serif;
                                    font-size: 13px;
                                    font-weight: 300;
                                }

                                .time {
                                    flex: 1.5;
                                    font-family: 'Montserrat', sans-serif;
                                    font-size: 13px;
                                    font-weight: 300;
                                    text-align: right;

                                    .hours {
                                        font-family: 'Montserrat', sans-serif;
                                        font-size: 10px;
                                        font-weight: 300;
                                    }
                                }


                                .delete {
                                    flex: 0.5;
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                }

                            }
                        }
                    }

                    .calendar-section {
                        width: calc(max(60%, 350px));
                        @media only screen and (max-width: 650px) {
                            width: 100%;
                       }
                        margin: 20px auto;
                    }

                    .edit-section {
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                        height: fit-content;
                        border: 1px solid rgb(227, 227, 227);
                        border-radius: 2px;
                        padding: 5px 0;

                        &__row {
                            display: flex;
                            flex-wrap: wrap;
                            padding: 5px;
                           
                            padding-bottom: 10px;
                            flex-direction: row;
                            align-items: center;

                            &--element {
                                flex: 1;
                                min-width: 200px;
                                padding-top: 20px;
                                text-align: center;

                                .date {
                                    font-family: 'Montserrat', sans-serif;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #000;

                                }
                            }
                        }
                    }
                }


                &--row {
                    padding: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;

                    @media only screen and (max-width: 750px) {
                        display: block;
                        padding: 0 4vw;
                    }


                    &__element {
                        flex: 1;
                        text-align: center;
                        padding-top: 15px;
                        padding-bottom: 15px;


                        &--icon {
                            flex: 1;
                        }

                        .total-price {
                            font-family: 'Montserrat', sans-serif;
                            font-size: 25px;
                            font-weight: 400;
                            color: #ff9804;
                        }
                    }

                    &__title-text {
                        width: 75%;

                        @media only screen and (max-width: 750px) {
                            width: 100%;

                        }

                        margin: auto;
                        font-family: 'Montserrat',
                        sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        color: #000;
                    }
                }

                &__templates-section {
                    padding: 10px;

                    &--heading {
                        display: flex;
                        padding: 10px;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;

                        .text {
                            font-family: 'Montserrat', sans-serif;
                            font-size: 13px;
                            font-weight: 300;
                            text-transform: uppercase;
                            margin-right: 10px;
                        }
                    }

                    .template-show-animate {
                        animation: showTemplateView 1s;

                    }

                    .template-hide-animate {
                        animation: hideTemplateView 1s;
                    }


                    @keyframes showTemplateView {
                        0% {
                            opacity: 0;
                            max-height: 0px;
                        }

                        100% {
                            opacity: 1;
                            max-height: 100px;
                        }
                    }

                    @keyframes hideTemplateView {
                        0% {
                            opacity: 1;
                            height: 50px;
                        }

                        100% {
                            opacity: 0;
                            height: 0;
                        }
                    }


                    &--row {
                        padding: 5px;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        width: 60%;

                        @media only screen and (max-width: 750px) {
                            width: 100%;
                        }

                        margin:auto;
                        display: flex;
                        flex-direction: row;
                        align-items: flex-end;

                        &__element {
                            flex: 1;
                            text-align: center;

                            &--icon {
                                flex: 1;
                            }
                        }

                        &__btn {
                            padding-left: 10px;
                        }
                    }

                }

                &--template-save {
                    padding: 5px;
                    padding-top: 20px;
                    padding-bottom: 30px;
                    text-align: center;

                    .show-template-name {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        animation: showTemplateName 1s;

                    }

                    .template-name {
                        width: 50%;
                        margin: auto;

                        @media only screen and (max-width: 750px) {
                            width: 100%;

                        }
                    }

                    .hide-template-name {
                        animation: hideTemplateName 1s;
                    }


                    @keyframes showTemplateName {
                        0% {
                            opacity: 0;
                            max-height: 0px;
                        }

                        100% {
                            opacity: 1;
                            max-height: 1000px;
                        }
                    }

                    @keyframes hideTemplateName {
                        0% {
                            opacity: 1;
                            height: 100px;
                        }

                        100% {
                            opacity: 0;
                            height: 0;
                        }
                    }

                }

                .price-text-field {
                    width: 40%;
                    min-width: 200px;
                    margin: auto;
                    text-align: center;
                    padding: 10px;
                }

                .event-name {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 20px;
                    font-weight: 300;
                    text-transform: capitalize;
                    text-align: center;
                    border-bottom-width: 1px;
                    border-bottom-color: #e3e3e3;
                    padding-bottom: 5px;
                }

                .event-between-detail {
                    display: flex;
                    justify-content: center;
                    padding: 5px;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 15px;
                    font-weight: 400;
                    text-transform: capitalize;
                    text-align: center;


                }

                .date {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 15;
                    font-weight: 300;
                    text-transform: capitalize;
                    text-align: center;
                    color: #777781;
                }
            }
        }

    }
}