.personal-details {
  margin: 5vh 5vw 0vh;
  padding: 10px 0px 120px;

  .input-group {
    display: flex;
    margin: 45px auto;
    width: 80%;
    font-size: 80%;
    align-items: flex-end;

    .input {
      background-color: transparent;
      border: none;
      padding: 10px;
      outline: none;
      flex: 1;
      text-align: center;

      &::placeholder {
        font-weight: 100;
        color: #fff;
      }


      input {

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      input[type=number] {
        -moz-appearance: textfield;
      }

    }

    .input-text {
      flex: 1;
      align-items: center;
      display: flex;
      padding: 10px;

      .icon {
        color: #777781;
        font-size: 20px;
      }

      .text {
        font-size: 15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        color: #777781;
        margin-left: 5px;
      }
    }

    .otp-verification {
      flex: 1;
      display: flex;
      padding: 10px;
    }

    .verify-btn {
      flex: 1;
      padding: 10px;
    }


  }


  @media only screen and (max-width: 1200px) {
    .input-group {
      width: 100%;
    }

  }

  @media only screen and (max-width: 749px) {
    padding: 0px 0px 60px;

    .input-group {
      display: block;
      margin: 45px auto;
      width: 100%;
      max-width: 500px;


      .verify-btn {
        text-align: center;
        padding: 30px 10px;
      }

      .input-text {
        justify-content: center;
        padding: 30px 10px;
      }
    }

  }

}