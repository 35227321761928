$yellow-background : hsla(35.95, 100%, 56.47%, 1);
$headingExtraLargeColor: hsla(0, 0%, 100%, 1);
$navigationLinkColor: hsla(0, 0%, 0.39%, 1);
$siteBackgroundColor: hsla(175, 38.1%, 24.71%, 1);
$paragraphMediumColor: hsla(0, 0%, 100%, 1);
$primaryButtonBackgroundColor: hsla(0, 0%, 100%, 1);
$primaryButtonTextColor: hsla(0, 0%, 0%, 1);
$redColor: hsla(359.63, 69.49%, 53.73%, 1);
$fourthSectionBackgroundColor : hsla(0, 0%, 0.39%, 1);
$whiteColor: hsla(0, 0%, 100%, 1);
$blackColor: hsla(0, 0%, 0.39%, 1);
$yellowLightColor : hsla(35.39, 100%, 57.45%, 0.7);

.landing-page {
    .banner-section {
        background-color: $yellow-background;

        &__header {
            padding-top: 1vw;
            padding-bottom: 1vw;
            padding-left: 2vw;
            padding-right: 2vw;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &--left {
                .logo {
                    width: auto;
                    max-height: 40px;
                    height: 6vw;
                }
            }

            &--right {

                .nav-btn {
                    font-size: 20px;
                    font-weight: 600;
                    font-style: normal;
                    color: $navigationLinkColor;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .nav-dropdown {
                    position: relative;

                    &:hover {
                        .nav-dropdown__content {
                            display: block;
                        }
                    }

                    &__btn {
                        font-size: 20px;
                        font-weight: 600;
                        font-style: normal;
                        color: $navigationLinkColor;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    &__content {
                        display: none;
                        position: absolute;
                        right: -1em;
                        z-index: 10;
                        text-align: right;
                        padding: 0.5em 1em;

                        &--link {
                            font-size: 20px;
                            font-weight: 600;
                            font-style: normal;
                            color: $navigationLinkColor;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        &__title {
            padding: 3.3vmax 0;

            &--one {
                color: $headingExtraLargeColor;
                font-size: 12.4vw;
                text-align: center;
                font-weight: bold;
                line-height: 1;

                @media only screen and (min-width: 1600px) {
                    font-size: 200px;
                }
            }

            &--two {
                color: $headingExtraLargeColor;
                font-size: 13.2vw;
                text-align: center;
                font-weight: bold;
                line-height: 1;

                @media only screen and (min-width: 1600px) {
                    font-size: 211px;
                }
            }
        }
    }

    .buttons-section {
        min-height: 66vh;
        width: 100%;
        background-color: $siteBackgroundColor;
        position: relative;


        .video {
            filter: opacity(82%);
            position: absolute;
            right: 0;
            bottom: 0;
            object-fit: cover;
            height: 100%;
            width: 100%;

        }

        .coloumn {
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__btn-row {

            width: 70vw;
            max-width: 1000px;
            height: auto;
            margin: auto;
            justify-content: center;
            display: grid;
            grid-template-columns: repeat(auto-fill, calc(max(33%, 200px)));


            @media only screen and (max-width: 1000px) {
                width: 80vw;
                grid-template-columns: repeat(auto-fill, calc(max(33%, 50px)));
            }

            @media only screen and (max-width: 600px) {
                width: 90vw;
            }

            @media only screen and (max-width: 400px) {
                width: 100vw;
            }

            &--btn {
                border: 2px solid;
                border-radius: 300px;
                padding: 10px 5px;
                border-color: $primaryButtonBackgroundColor;
                text-align: center;
                margin-right: calc(min(40px, 4vw));
                margin-left: calc(min(40px, 4vw));
                display: flex;
                justify-content: center;
                align-items: center;
                color: $primaryButtonBackgroundColor;
                transition: all 500ms;

                @media only screen and (max-width: 600px) {
                    margin-right: calc(min(10px, 1vw));
                    margin-left: calc(min(10px, 1vw));
                    border-radius: 100px;
                }

                &__text {
                    font-weight: 600;
                    font-style: normal;
                    letter-spacing: .02em;
                    font-size: 1.4vw;

                    @media only screen and (min-width: 1600px) {
                        font-size: 1rem;
                    }

                    @media only screen and (max-width: 1000px) {
                        font-size: 14px;
                    }

                    @media only screen and (max-width: 500px) {
                        font-size: 10px;
                    }

                    text-transform: uppercase;
                    text-align: center;
                    margin: auto;
                }

                &:hover {
                    background: $primaryButtonBackgroundColor;
                    color: $primaryButtonTextColor;
                    cursor: pointer;
                }

            }

        }

    }

    .creative-section {
        background-color: $redColor;

        .marquee {
            padding: 3vh 6vw 0;
            max-width: 1500px;
            margin: auto;

            &__text {
                text-align: center;
                font-size: 20px;
                color: $whiteColor;
                fill: $whiteColor;
            }

        }

        .main {
            display: flex;
            flex-direction: row;
            padding: 6vh 6vw;
            max-width: 1500px;
            margin: auto;

            @media only screen and (max-width: 700px) {
                display: block;
            }

            &__text-area {
                flex: 1;
                padding: 0 2vw;

                &--title {
                    font-weight: bold;
                    font-size: calc(min(6.8vw, 102px));
                    @media only screen and (max-width: 700px) {
                        font-size: 30px;
                    }
                    color: $headingExtraLargeColor;
                    margin-bottom: calc(min(1vw,10px));
                }

                &--description {
                    font-size: 16px;

                    @media only screen and (max-width: 700px) {
                        font-size: 14px;
                    }
                    font-weight: 400;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: .01em;
                    color: $headingExtraLargeColor;
                    margin-bottom: 2vw;
                }

                &--details {
                    font-size: 16px;
                    @media only screen and (max-width: 700px) {
                        font-size: 14px;
                    }
                    font-weight: 400;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: .01em;
                    color: $headingExtraLargeColor;

                    .details-btn {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding-top: 15px;
                        padding-bottom: 15px;

                        &--icon {
                            margin-right: 14px;
                            font-size: 25px;
                        }

                        .icon-open {
                            animation: iconRotateAnimation 500ms;
                            transform: rotate(180deg);
                        }

                        .icon-close {
                            animation: iconReverseRotateAnimation 500ms;
                            transform: rotate(0deg);
                        }

                        @keyframes iconRotateAnimation {
                            0% {
                                transform: rotate(0deg);

                            }

                            100% {
                                transform: rotate(180deg);
                            }
                        }

                        @keyframes iconReverseRotateAnimation {
                            0% {
                                transform: rotate(180deg);
                            }

                            100% {
                                transform: rotate(0deg);
                            }
                        }

                        &--text {}

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    ul {
                        margin-inline-start: 0px;
                        margin-inline-end: 0px;
                        padding-inline-start: 60px;
                        list-style: initial;

                        li {
                            margin-bottom: 0.5em;
                            margin-top: 0.5em;
                        }
                    }


                    .expand {
                        animation: expandAnimation 1s;


                    }

                    .shrink {
                        animation: shrinkAnimation 1s;

                    }


                    @keyframes expandAnimation {
                        0% {
                            opacity: 0;
                            max-height: 0px;
                        }

                        100% {
                            opacity: 1;
                            max-height: 1000px;
                        }
                    }

                    @keyframes shrinkAnimation {
                        0% {
                            opacity: 1;
                            height: 50px;
                        }

                        100% {
                            opacity: 0;
                            height: 0;
                        }
                    }
                }

                &--bottom {
                    display: flex;

                    @media only screen and (max-width: 700px) {
                        justify-content: center;
                    }

                    .btn {
                        border: 2px solid;
                        border-radius: 300px;
                        padding: 10px 20px;
                        border-color: $primaryButtonBackgroundColor;
                        text-align: center;
                        margin: 2vw 0;

                        color: $primaryButtonBackgroundColor;
                        transition: all 500ms;

                        &__text {
                            font-weight: 500;
                            font-style: normal;
                            letter-spacing: .02em;
                            font-size: 1.4vw;

                            @media only screen and (min-width: 1600px) {
                                font-size: 1rem;
                            }
        
        
                            @media only screen and (max-width: 1000px) {
                                font-size: 14px;
                            }
        
                            text-transform: uppercase;
                            text-align: center;
                            margin: auto;

                        }

                        &:hover {
                            background: $primaryButtonBackgroundColor;
                            color: $primaryButtonTextColor;
                            cursor: pointer;
                        }
                    }


                }
            }

            &__photo-area {
                flex: 1;
                padding: 0 2vw;
                position: relative;

                @media only screen and (max-width: 700px) {
                    height: 40vh;
                    margin-top: 30px;
                }

                .photo-1 {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 80%;
                    width: 60%;

                    .img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .photo-2 {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 80%;
                    width: 60%;

                    .img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

        }
    }

    .clients-section {
        background-color: $fourthSectionBackgroundColor;
        position: relative;

        .img-background {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            .img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
                filter: opacity(82%);
            }

        }

        .main {
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            padding: 6vh 6vw;
            max-width: 1500px;
            margin: auto;

            @media only screen and (max-width: 700px) {
                display: block;
            }

            &__text-area {
                flex: 1;
                padding: 0 2vw;
                text-align: right;

                &--title {
                    font-weight: bold;
                    font-size: calc(min(6.8vw, 102px));
                    @media only screen and (max-width: 700px) {
                        font-size: 30px;
                    }
                    line-height: 1.2em;
                    color: $headingExtraLargeColor;
                    text-align: right;
                    margin-bottom: calc(min(1vw,10px));
                }

                &--description {
                    margin: 0 0 0 auto;
                    width: 90%;

                    @media only screen and (max-width: 700px) {
                        width: 100%;
                    }

                    font-size: 16px;

                    @media only screen and (max-width: 700px) {
                        font-size: 14px;
                    }
                    font-weight: 400;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: .01em;
                    color: $headingExtraLargeColor;
                    margin-bottom: 3vw;
                    text-align: right;
                }

                &--details {
                    font-size: 16px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: .01em;
                    color: $headingExtraLargeColor;

                    .details-btn {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding-top: 15px;
                        padding-bottom: 15px;

                        &--icon {
                            margin-right: 14px;
                            font-size: 25px;
                        }

                        .icon-open {
                            animation: iconRotateAnimation 500ms;
                            transform: rotate(180deg);
                        }

                        .icon-close {
                            animation: iconReverseRotateAnimation 500ms;
                            transform: rotate(0deg);
                        }

                        @keyframes iconRotateAnimation {
                            0% {
                                transform: rotate(0deg);

                            }

                            100% {
                                transform: rotate(180deg);
                            }
                        }

                        @keyframes iconReverseRotateAnimation {
                            0% {
                                transform: rotate(180deg);
                            }

                            100% {
                                transform: rotate(0deg);
                            }
                        }

                        &--text {}

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    ul {
                        margin-inline-start: 0px;
                        margin-inline-end: 0px;
                        padding-inline-start: 60px;
                        list-style: initial;

                        li {
                            margin-bottom: 0.5em;
                            margin-top: 0.5em;
                        }
                    }


                    .expand {
                        animation: expandAnimation 1s;

                    }

                    .shrink {
                        animation: shrinkAnimation 1s;
                    }


                    @keyframes expandAnimation {
                        0% {
                            opacity: 0;
                            max-height: 0px;
                        }

                        100% {
                            opacity: 1;
                            max-height: 1000px;
                        }
                    }

                    @keyframes shrinkAnimation {
                        0% {
                            opacity: 1;
                            height: 200px;
                        }

                        100% {
                            opacity: 0;
                            height: 0;
                        }
                    }
                }

                &--bottom {
                    display: flex;
                    justify-content: flex-end;

                    @media only screen and (max-width: 700px) {
                        justify-content: center;
                    }

                    .btn {
                        border: 2px solid;
                        border-radius: 300px;
                        padding: 10px 20px;
                        border-color: $primaryButtonBackgroundColor;
                        text-align: center;
                        margin: 2vw 0;
                        transition: all 500ms;
                        color: $primaryButtonBackgroundColor;

                        &__text {
                            font-weight: 500;
                            font-style: normal;
                            letter-spacing: .02em;                
                            text-transform: uppercase;
                            text-align: center;
                            margin: auto;
                            font-size: 1.4vw;

                            @media only screen and (min-width: 1600px) {
                                font-size: 1rem;
                            }
        
        
                            @media only screen and (max-width: 1000px) {
                                font-size: 14px;
                            }
        
                        }

                        &:hover {
                            background: $primaryButtonBackgroundColor;
                            color: $primaryButtonTextColor;
                            cursor: pointer;
                        }
                    }


                }
            }

            &__photo-area {
                flex: 1;
                padding: 0 2vw;
                position: relative;


                .photo-1 {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 80%;
                    width: 60%;

                    .img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .photo-2 {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 80%;
                    width: 60%;

                    .img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

        }
    }


    .hospitality-section {
        background-color: $whiteColor;

        .marquee {
            padding: 3vh 6vw 0;
            max-width: 1500px;
            margin: auto;

            &__text {
                text-align: center;
                font-size: 20px;
                color: $redColor;
                fill: $redColor;
            }

        }

        .main {
            display: flex;
            flex-direction: row;
            padding: 6vh 6vw 10vh;
            max-width: 1500px;
            margin: auto;

            @media only screen and (max-width: 700px) {
                display: block;
            }

            &__text-area {
                flex: 1;
                padding: 0 2vw;

                &--title {
                    font-weight: bold;
                    font-size: calc(min(6.8vw, 102px));
                    @media only screen and (max-width: 700px) {
                        font-size: 30px;
                    }
                    line-height: 1.2em;
                    color: $redColor;
                    margin-bottom: calc(min(1vw,10px));
                }

                &--description {
                    width: 85%;
                    font-size: 16px;
                    @media only screen and (max-width: 700px) {
                        font-size: 14px;
                        width: 100%;
                    }
                    font-weight: 400;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: .01em;
                    color: $blackColor;
                    margin-bottom: 2vw;
                }

                &--details {
                    width: 85%;
                    font-size: 16px;
                    @media only screen and (max-width: 700px) {
                        font-size: 14px;
                        width: 100%;
                    }
                    font-weight: 400;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: .01em;
                    color: $blackColor;

                    .details-btn {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding-top: 15px;
                        padding-bottom: 15px;

                        &--icon {
                            margin-right: 14px;
                            font-size: 25px;
                        }

                        .icon-open {
                            animation: iconRotateAnimation 500ms;
                            transform: rotate(180deg);
                        }

                        .icon-close {
                            animation: iconReverseRotateAnimation 500ms;
                            transform: rotate(0deg);
                        }

                        @keyframes iconRotateAnimation {
                            0% {
                                transform: rotate(0deg);

                            }

                            100% {
                                transform: rotate(180deg);
                            }
                        }

                        @keyframes iconReverseRotateAnimation {
                            0% {
                                transform: rotate(180deg);
                            }

                            100% {
                                transform: rotate(0deg);
                            }
                        }

                        &--text {}

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    ul {
                        margin-inline-start: 0px;
                        margin-inline-end: 0px;
                        padding-inline-start: 60px;
                        list-style: initial;

                        li {
                            margin-bottom: 0.5em;
                            margin-top: 0.5em;
                        }
                    }


                    .expand {
                        animation: expandAnimation 1s;

                    }

                    .shrink {
                        animation: shrinkAnimation 1s;
                    }


                    @keyframes expandAnimation {
                        0% {
                            opacity: 0;
                            max-height: 0px;
                        }

                        100% {
                            opacity: 1;
                            max-height: 1000px;
                        }
                    }

                    @keyframes shrinkAnimation {
                        0% {
                            opacity: 1;
                            height: 200px;
                        }

                        100% {
                            opacity: 0;
                            height: 0;
                        }
                    }
                }

                &--bottom {
                    display: flex;

                    @media only screen and (max-width: 700px) {
                        justify-content: center;
                    }

                    .btn {
                        border: 2px solid;
                        border-radius: 300px;
                        padding: 10px 20px;
                        border-color: $blackColor;
                        text-align: center;
                        margin: 2vw 0;
                        transition: all 500ms;
                        color: $blackColor;

                        &__text {
                            font-weight: 500;
                            font-style: normal;
                            letter-spacing: .02em;
                            font-size: 1.4vw;

                            @media only screen and (min-width: 1600px) {
                                font-size: 1rem;
                            }
        
        
                            @media only screen and (max-width: 1000px) {
                                font-size: 14px;
                            }
        
                            text-transform: uppercase;
                            text-align: center;
                            margin: auto;
                        }

                        &:hover {
                            background: $blackColor;
                            color: $whiteColor;
                            cursor: pointer;
                        }
                    }


                }
            }

            &__photo-area {
                flex: 1;
                padding: 0 2vw;
                position: relative;

                @media only screen and (max-width: 700px) {
                    height: 40vh;
                    margin-top: 30px;
                }

                .photo-1 {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 80%;
                    width: 60%;

                    .img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .photo-2 {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 80%;
                    width: 60%;

                    .img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

        }
    }


    .about-section {
        background-color: $yellow-background;



        .main {
            display: flex;
            flex-direction: row;
            padding: 6vh 8vw;
            max-width: 1500px;
            margin: auto;

            @media only screen and (max-width: 700px) {
                display: block;
            }

            &__text-area {
                flex: 1.7;
                padding: 0 0vw;

                &--title {
                    font-weight: bold;
                    font-size: calc(min(6.4vw, 102px));
                    @media only screen and (max-width: 700px) {
                        font-size: 30px;
                    }
                    line-height: 1.3em;
                    color: $headingExtraLargeColor;
                    margin-bottom: calc(min(1vw,10px));
                }

                &--description {
                    font-size: 16px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: .01em;
                    color: $headingExtraLargeColor;
                    margin-bottom: calc(max(2vw,15px));
                    width: 80%;

                    @media only screen and (max-width: 700px) {
                        font-size: 14px;
                        width: 100%;
                    }
                }

                &--details {
                    font-size: 16px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: .01em;
                    color: $headingExtraLargeColor;

                    .details-btn {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding-top: 15px;
                        padding-bottom: 15px;

                        &--icon {
                            margin-right: 14px;
                            font-size: 25px;
                        }

                        .icon-open {
                            animation: iconRotateAnimation 500ms;
                            transform: rotate(180deg);
                        }

                        .icon-close {
                            animation: iconReverseRotateAnimation 500ms;
                            transform: rotate(0deg);
                        }

                        @keyframes iconRotateAnimation {
                            0% {
                                transform: rotate(0deg);

                            }

                            100% {
                                transform: rotate(180deg);
                            }
                        }

                        @keyframes iconReverseRotateAnimation {
                            0% {
                                transform: rotate(180deg);
                            }

                            100% {
                                transform: rotate(0deg);
                            }
                        }

                        &--text {}

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    ul {
                        margin-inline-start: 0px;
                        margin-inline-end: 0px;
                        padding-inline-start: 60px;
                        list-style: initial;

                        li {
                            margin-bottom: 0.5em;
                            margin-top: 0.5em;
                        }
                    }


                    .expand {
                        animation: expandAnimation 1s;

                    }

                    .shrink {
                        animation: shrinkAnimation 1s;
                    }


                    @keyframes expandAnimation {
                        0% {
                            opacity: 0;
                            max-height: 0px;
                        }

                        100% {
                            opacity: 1;
                            max-height: 1000px;
                        }
                    }

                    @keyframes shrinkAnimation {
                        0% {
                            opacity: 1;
                            height: 200px;
                        }

                        100% {
                            opacity: 0;
                            height: 0;
                        }
                    }
                }

                &--bottom {
                    display: flex;

                    @media only screen and (max-width: 700px) {
                        justify-content: center;
                    }

                    .btn {
                        border: 2px solid;
                        border-radius: 300px;
                        padding: 3vh 4vw;
                        border-color: $primaryButtonBackgroundColor;
                        text-align: center;
                        margin: 2vw 0;

                        color: $primaryButtonBackgroundColor;

                        &__text {
                            font-weight: 500;
                            font-style: normal;
                            letter-spacing: .02em;
                            font-size: 0.9rem;
                            text-transform: uppercase;
                            text-align: center;
                            margin: auto;
                        }

                        &:hover {
                            background: $primaryButtonBackgroundColor;
                            color: $primaryButtonTextColor;
                            cursor: pointer;
                        }
                    }


                }
            }

            &__photo-area {
                flex: 1;
                padding: 0 1vw;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .photo {
                    width: 70%;
                    aspect-ratio: 1;
                    height: auto;
                    border-radius: 50%;


                    .img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                        object-position: 70%;
                    }
                }


            }

        }
    }

    .community-section {
        background-color: $whiteColor;

        .main {
            padding: 6vh 8vw;
            max-width: 1500px;
            margin: auto;

            &__title {
                font-weight: bold;
                font-size: calc(min(4vw, 80px));
                @media only screen and (max-width: 700px) {
                    font-size: 28px;
                }
                line-height: 1.2em;
                color: $yellow-background;
                letter-spacing: 0em;
            }

            &__swiper {
                .community-card {
                    text-align: center;
                    padding: 4vh 2vw 10px 2vw;

                    .image-view {
                        width: calc(max(8vw, 100px));
                        height: calc(max(8vw, 100px));
                        margin: auto;
                        border-radius: 50%;

                        .img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }

                    .text {
                        margin: 20px 0;
                        font-size: 16px;
                        font-weight: 400;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: .01em;
                    }

                    .title {
                        font-size: 12px;
                        font-weight: 400;
                        font-style: italic;
                        line-height: 1.5;
                        letter-spacing: .01em;
                    }
                }
            }

            &__ctrl-btns {
                display: flex;
                justify-content: center;
                align-items: center;

                &--left {
                    margin: 0px 10px;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: $yellowLightColor;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 500ms;

                    &:hover {
                        background-color: $yellow-background;
                        cursor: pointer;
                    }

                    .icon {
                        font-size: 25px;
                        color: $whiteColor;

                    }
                }

                &--right {
                    margin: 0px 10px;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    background-color: $yellowLightColor;
                    align-items: center;
                    transition: all 500ms;

                    &:hover {
                        background-color: $yellow-background;
                        cursor: pointer;
                    }

                    .icon {
                        font-size: 25px;
                        color: $whiteColor;


                    }

                }
            }


        }
    }

    .instagram-section {
        background-color: $blackColor;

        .main {
            padding: 6vh 8vw;
            max-width: 1500px;
            margin: auto;

            &__title {
                text-align: center;
                color: $redColor;
                ;
                font-weight: bold;
                font-size: calc(min(6.4vw, 117px));
                @media only screen and (max-width: 700px) {
                    font-size: 30px;
                }
                line-height: 1.3em;
                letter-spacing: 0em;
                margin-bottom: 2vh;
            }

            &__btn-grp {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn {
                    margin: 0px 10px;
                    color: $whiteColor;
                    transition: all 500ms;

                    &:hover {
                        cursor: pointer;
                        color: hsla(0, 0%, 100%, 0.8);
                    }

                    .icon {

                        font-size: 25px;
                    }
                }

            }
        }
    }
}

.drawer {
    min-width: 200px;
    width: 30%;
    height: 100%;
    min-height: 100vh;
    background-color: #020d15;
    padding: 20px 0px;

    .section {
        padding: 10px 0px;
        border-bottom: #052034 solid 0.2px;
        background-color: #020d15;

        &__title {
            display: block;
            color: #777781;
            font-size: 12px;
            text-align: center;
            padding: 10px;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
            word-break: break-word;
        }

        &__btn {
            color: #fff;
            padding: 12px 12px;
            text-decoration: none;
            display: block;
            text-transform: uppercase;
            font-size: 10px;
            text-align: center;
            background-color: #020d15;
            font-family: 'Montserrat', sans-serif;

            &:hover {
                background-color: #ffa626;
                color: #fff;
                text-decoration: none;
                cursor: pointer;
            }

            &:active {
                color: #ffa626;

            }

            &.active {
                color: #ffa626;
                border-right: #ffa626 3px solid;
                background-color: #052033;

                &:hover {
                    background-color: #ffa626;
                    color: #fff;
                }
            }


        }
    }
}