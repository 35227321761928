.result-page {
    margin: 5vh 5vw;

    .heading {
        font-family: 'Montserrat', sans-serif;
        font-size: 4vw;
        font-weight: bold;
        text-align: center;
    }

    .main {
        width: 50%;
        margin: 10px auto;

        .text {
            font-family: 'Montserrat', sans-serif;
            font-size: 1.5vw;
            font-weight: 400;
            text-align: center;
            margin: 20px 0px;
        }
        .btn-grp{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            @media only screen and (max-width: 500px) {
                display: block;
            }

            .sign-in-btn{
                text-align: center;
                margin: 20px 10px;
            }
            .listing-btn{
                text-align: center;
                margin: 20px 10px;
            }
        }
        

        .image {
            text-align: center;
            display: block;
            width: 80%;
            min-width: 150px;
            height: auto;
            margin: 0 auto;
        }

        .listing-details{
            .text{
                font-size: 1.2vw;
                font-weight: 400;
            }

            .link{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                .text{
                    font-size: 1.1vw;
                    font-weight: 300;
                    font-style: italic;
                    margin: 0px 10px;
                }
            }
            
        }
    }

    @media only screen and (max-width: 960px) {
        .heading{
            font-size: 32px;
        }
        .main{
            width: 75%;
            .text  {
                font-size: 16px;
            }

            .image{
                width: 90%;
            }

            .listing-details{
                .text{
                    font-size: 14px;
                }
    
                .link{
                    .text{
                        font-size: 12px;
                    }
                }
                
            }
        }
    }


}