.client-bookings-page {

    .calendar-detail {
        height: calc(100vh - 80px);
        display: flex;
        flex-direction: row;

        .month-year {
            flex: 1;
            height: calc(100vh - 80px);
            background-color: #000000;
            background-image: linear-gradient(45deg, #000000 0%, #052034 100%);
            background-size: cover;
            overflow-x: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .year-details {
                margin-top: 20px;
                margin-bottom: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;

                .year {
                    font-weight: 300;
                    text-transform: uppercase;
                    font-family: 'Montserrat', sans-serif;
                    color: #fff
                }
            }
        }

        .main-section {
            flex: 3;
            box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);
            position: relative;
            height: calc(100vh - 80px);
            overflow-x: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .calendar {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .month-name {
                    font-weight: 200;
                    text-transform: uppercase;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 30px;
                    padding: 20px;
                }

                .down-icon {}
            }
        }


        .booking-list {
            flex: 1.5;
            height: calc(100vh - 80px);
            overflow-x: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            &__title {
                margin-top: 20px;
                margin-bottom: 20px;
                font-size: 20px;
                font-weight: 400;
                text-transform: uppercase;
                font-family: 'Montserrat', sans-serif;
                text-align: center;
            }

            .no-bookings {
                font-family: 'Montserrat', sans-serif;
                font-size: 16px;
                font-weight: 300;
                color: #777781;
                text-align: center;
            }
        }

    }

    .event-detail {
        background-color: #000000;
        background-image: linear-gradient(215deg, #000000 0%, #052034 100%);
        background-size: cover;
        background-position: center;
        padding-left: 5vw;
        padding-right: 5vw;
        min-height: 100vh;
        height: 100%;
        position: relative;
        display: flex;

        .mySwiper {
            z-index: 0;
        }

        & .booking-swiper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        & .swiper-container {
            flex: 1;
        }

        & .swiper-slide {
            height: max-content;
            padding: 10px 20px;
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
        }

        & .empty-list {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            font-weight: 300;
            color: #777781;
            text-align: center;
        }

        .up-icon {
            position: absolute;
            right: 30px;
            top: 30px;
        }
    }

}